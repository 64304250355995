import Http from '../Http'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Payment extends Http {
  public async createPayment(token: IToken, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.paymentsPath(),
      token,
      {},
      data,
    )) as any
  }

  public async updatePayment(token: IToken, paymentId, data): Promise<any> {
    return (await this.spreeResponse(
      'patch',
      Routes.paymentPath(paymentId),
      token,
      {},
      data,
    )) as any
  }

  public async updatePaymentState(
    token: IToken,
    paymentId,
    data,
  ): Promise<any> {
    return (await this.spreeResponse(
      'patch',
      Routes.paymentStatePath(paymentId),
      token,
      {},
      data,
    )) as any
  }

  // public async deletePayment(token: IToken, paymentId, data): Promise<any> {
  //   return (await this.spreeResponse(
  //     'patch',
  //     Routes.paymentPath(paymentId),
  //     token,
  //     {},
  //     data,
  //   )) as any
  // }
}
