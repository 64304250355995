import { ApiContext, Cart } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function deletePayout(
  { client, config }: ApiContext,
  payoutId,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.payouts.delete({ bearerToken: token }, payoutId)

    if (result.isSuccess()) {
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
