import Http from '../Http'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Tools extends Http {
  public async genProductFbDes(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.toolProductFbDesPath(),
      token,
      params,
    )) as any
  }
}
