import { ApiContext, Cart } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function createOrder(
  { client, config }: ApiContext,
  params,
): Promise<Cart> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.create(
      { bearerToken: token },
      {
        order: {
          ...params.order,
        },
        include: 'created_by',
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      const cart = deserializeCart(payload.data, payload.included, config)
      return cart
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
