import type { ApiContext } from '../../types'
import {
  addHostToProductImages,
  deserializeSingleProductVariants,
} from '../serializers/product'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function adjustProductStock(
  { client, config }: ApiContext,
  variantId,
  stock_location_id,
  stock_location_area_id,
  quantity,
) {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.stockItem.updateStockOnly(
      { bearerToken: token },
      {
        variant_id: variantId,
        stock_location_id,
        stock_location_area_id,
        stock_movement: { quantity },
      },
    )
    return true
    // if (result.isSuccess()) {
    //   // const data = result.success()
    //   // const productsData = addHostToProductImages(data, config)
    //   // const r = deserializeSingleProductVariants(productsData)[0]
    //   // console.log(r)
    //   return r
    // } else {
    //   // throw result.fail()
    // }
  } catch (e) {
    console.error(e)
    throw e
  }
}
