import {
  JsonApiDocument,
  JsonApiResponse,
} from '@spree/storefront-api-v2-sdk/types/interfaces/JsonApi'
import { ApiConfig } from '../../types'

const findParent = (taxon, apiTaxons) => {
  if (
    taxon.attributes.is_root ||
    taxon.relationships == null ||
    taxon.relationships.parent == null
  ) {
    return undefined
  }

  const parentRelationship = taxon.relationships.parent
  const parentId = parentRelationship.data.id

  const parent = apiTaxons.find((taxon) => taxon.id === parentId)
  if (!parent) {
    return undefined
  }
  // console.log(taxon)
  // console.log(apiTaxons)
  return {
    id: parent.id,
    name: parent.attributes.name,
    slug: parent.attributes.permalink,
    count: parent.attributes.count,
    parent: findParent(parent, apiTaxons),
  }
}

const findImage = (taxon, included) => {
  if (
    taxon.relationships == null ||
    taxon.relationships.image == null ||
    taxon.relationships.image.data === null ||
    included === null
  )
    return null
  const imageId = taxon.relationships.image.data.id
  const image = included.find(
    (i) => i.id === imageId && i.type === 'taxon_image',
  )
  if (image === undefined) {
    return null
  }
  return image.attributes.original_url
}

const findItems = (taxon, apiTaxons, included) => {
  // console.log('findItems')
  // console.log(taxon)
  // console.log(apiTaxons)
  // console.log(included)
  if (included == null) {
    return []
  }
  const items = included.filter(
    (t) =>
      !t.attributes.is_root &&
      t.attributes.parent_id == taxon.id &&
      t.type == 'taxon',
  )
  return items
    .map((item) => ({
      id: item.id,
      name:
        item.attributes.depth == 2
          ? taxon.attributes.name + ' > ' + item.attributes.name
          : item.attributes.name,
      slug: item.attributes.permalink,
      count: item.attributes.count,
      image: findImage(item, included),
      depth: item.attributes.depth,
      position: item.attributes.position,
      taxonomy_id: item.attributes.taxonomy_id,
      items: findItems(item, apiTaxons, included),
      parent: findParent(item, apiTaxons),
    }))
    .sort(function (a, b) {
      return a.id - b.id
    })
}

export const deserializeTaxons = (apiTaxons, included = null) => {
  const mainTaxons = apiTaxons
  return mainTaxons.map((taxon) => {
    const items = findItems(taxon, apiTaxons, included)
    const allItems = []
    for (const i of items) {
      allItems.push(i)
      for (const y of i.items) {
        allItems.push(y)
      }
    }
    return {
      id: taxon.id,
      name: taxon.attributes.name,
      slug: taxon.attributes.permalink,
      count: taxon.attributes.count,
      image: findImage(taxon, included),
      // items: items,
      items: allItems,
      parent: findParent(taxon, apiTaxons),
    }
  })
}
const addHostToImage = (image, config: ApiConfig) => ({
  ...image,
  attributes: {
    ...image.attributes,
    original_url: config.backendUrl + image.attributes.original_url,
    styles: image.attributes?.styles
      ? image.attributes.styles.map((style) => ({
          width: style.width,
          height: style.height,
          url: config.backendUrl + style.url,
        }))
      : [],
  },
})

const addHostToTaxonIncluded = (
  included: JsonApiDocument[],
  config: ApiConfig,
) =>
  included.map((e) =>
    e.type === 'taxon_image' ? addHostToImage(e, config) : e,
  )

export const addHostToTaxonImages = <DocumentType extends JsonApiResponse>(
  apiProductsData: DocumentType,
  config: ApiConfig,
): DocumentType => ({
  ...apiProductsData,
  included: addHostToTaxonIncluded(apiProductsData.included, config),
})
