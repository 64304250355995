import { ApiContext, Cart } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeShipment } from '@/api_client/api/serializers/shipping'
import { deserializePaymentMethods } from '@/api_client/api/serializers/payment'

export default async function getCartMetaData({
  client,
  config,
}: ApiContext): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.metaData({ bearerToken: token })
    // console.log(result.success())
    const data = result.success()
    return {
      shipping_methods: data.shipping_methods.data.map((s) =>
        deserializeShipment(s, []),
      ),
      payment_methods: data.payment_methods.data
        .map((s) => deserializePaymentMethods(s))
        .sort((a, b) =>
          a.type == 'Spree::PaymentMethod::Cash' ||
          a.type == 'Spree::PaymentMethod::CashOnDelivery'
            ? -1
            : 1,
        ),
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
