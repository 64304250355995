import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import User from '@/views/User.vue'
import { h, resolveComponent } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Products.vue'),
      },
      {
        path: '/products/:id',
        name: 'Product',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Product.vue'),
      },
      {
        path: '/orders',
        name: 'Orders',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Orders.vue'),
      },
      {
        path: '/orders/:id',
        name: 'Order',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Order.vue'),
      },
      {
        path: '/consignments',
        name: 'Consignments',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Consignments.vue'
          ),
      },
      {
        path: '/consignments/:id',
        name: 'Consignment',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Consignment.vue'),
      },
      {
        path: '/payouts',
        name: 'Payouts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Payouts.vue'),
      },
      {
        path: '/payouts/:id',
        name: 'Payout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Payout.vue'),
      },
      {
        path: '/cms_pages',
        name: 'CMS pages',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/CmsPages.vue'),
      },
      {
        path: '/cms_pages/:id',
        name: 'CMS page',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/CmsPage.vue'),
      },
      {
        path: '/report/sales',
        name: 'Sales Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/SalesReport.vue'
          ),
      },
      {
        path: '/report/sales_old',
        name: 'Sales(Old) Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/SalesOldReport.vue'
          ),
      },
      {
        path: '/report/consignments',
        name: 'Consignments Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/ConsignmentsReport.vue'
          ),
      },
      {
        path: '/report/payouts',
        name: 'Payouts Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/PayoutsReport.vue'
          ),
      },
      {
        path: '/report/products',
        name: 'Products Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/ProductsReport.vue'
          ),
      },
      {
        path: '/tools/product-fb-des',
        name: 'product-fb-des',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductFbDes.vue'
          ),
      },
      {
        path: '/tools/product-location',
        name: 'product-location',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductLocation.vue'
          ),
      },
      {
        path: '/customers',
        name: 'Customers',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Users.vue'),
      },
      {
        path: '/employees',
        name: 'Employees',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Employees" */ '@/views/Employees.vue'),
      },
      {
        path: '/customers/:id',
        name: 'Customer',
        component: User,
        children: [
          {
            path: 'info',
            alias: '/customers/:id',
            name: 'Customer Info',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailInfo.vue'
              ),
          },
          {
            path: 'orders',
            alias: '/customers/:id/orders',
            name: 'Customer Orders',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailOrders.vue'
              ),
          },
          {
            path: 'consignments',
            name: 'Customer Consignments',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailConsignments.vue'
              ),
          },
          {
            path: 'credits',
            name: 'Customer Credits',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailCredits.vue'
              ),
          },
          {
            path: 'payouts',
            name: 'Customer Payouts',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailPayouts.vue'
              ),
          },
          {
            path: 'reset-password',
            name: 'Customer Reset Passwrod',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailResetPassword.vue'
              ),
          },
          {
            path: 'update-email',
            name: 'Customer email',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailUpdateEmail.vue'
              ),
          },
          {
            path: 'Roles',
            name: 'User Roles',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailRoles.vue'
              ),
          },
          {
            path: 'payout-methods',
            name: 'Customer Payout Methods',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailPayoutMethods.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/pos',
    name: 'POS',
    component: () => import('@/views/POS.vue'),
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500.vue'),
      },
      // {
      //   path: 'login',
      //   name: 'Login',
      //   component: () => import('@/views/pages/Login.vue'),
      // },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
