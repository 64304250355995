import Http from '../Http'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class LogEntry extends Http {
  public async show(token: IToken, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.logEntryPath(),
      token,
      params,
    )) as any
  }
}
