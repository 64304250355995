// import { Logger } from '@vue-storefront/core'
import { ApiContext, Order } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeCart } from '../serializers/cart'
import { cartParams } from '@/api_client/api/common/cart'
import { IQuery } from '@/api_client/interfaces/Query'

export default async function getOrders(
  { client, config }: ApiContext,
  params: IQuery = {},
  is_empty_include = false,
): Promise<any> {
  try {
    // console.log(params.filter)
    // console.log(params.sort)
    const token = await getCurrentBearerToken({ client, config })
    const isShowCompleteOnly = params.filter.state_eq == 'complete'
    const response = await client.account.completedOrdersList(
      { bearerToken: token },
      {
        ...params,
        per_page: params.per_page || 10,
        include: is_empty_include
          ? ''
          : 'user,ship_address,payments,payments.payment_method,' +
            (params.include || ''),
        filter: {
          ...params.filter,
          completed_at_not_null: isShowCompleteOnly ? 1 : '',
        },
        sort:
          params.sort || (isShowCompleteOnly ? '-completed_at' : '-created_at'),
        // sort: params.sort || '-completed_at', // hard to filter orders if not completed
        // sort: '-completed_at',
        // 'line_items.variant.product.images,shipping_address,promotions,shipments,payments,user',
      },
    )

    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      const data = payload.data.map((order) =>
        deserializeCart(order, payload.included, config),
      )
      // payload.data = deserializedData
      return { ...payload, data }
    } else {
      // console.log(response.fail())
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
