import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeRole } from '@/api_client/api/serializers/user'
import { SaleReport } from '@/api_client/types/report'

export default async function getProductFbDes(
  { client, config }: ApiContext,
  skus,
): Promise<any> {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.tools.genProductFbDes({ bearerToken }, { skus })
  if (response.isSuccess()) {
    return response.success()
  } else {
    return null
  }
}
