
import { watch } from 'vue'
import { useStore } from 'vuex'
import { AuthHelper } from '@/api_client'

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  // created() {
  //   const auth = AuthHelper.getOAuthToken()
  //   console.log(this.$route.path)
  //   if (
  //     this.$route.path !== '/' &&
  //     (auth.access_token == null || !AuthHelper.isRoleExist())
  //   ) {
  //     AuthHelper.removeOAuthToken()
  //     // this.$router.push('/#/login')
  //     location.replace('/#/login')
  //     location.reload()
  //   }
  // },
  mounted() {
    const auth = AuthHelper.getOAuthToken()
    // window.location.pathname
    // console.log(window.location)
    if (
      !window.location.hash.startsWith('#/login') &&
      !window.location.hash.startsWith('login#/') &&
      (auth.access_token == null || !AuthHelper.isRoleExist())
    ) {
      AuthHelper.removeOAuthToken()
      window.location.href = '/#/login'
      // location.replace('/#/login')
      // location.reload()
    }
  },
}
