import { OrderPromotion, Promotion } from '@/api_client/types'
import { PromotionAttr } from '@/api_client/interfaces/Promotion'

const findAttachment = (attachments: any[], type: string) => {
  return attachments?.find((e) => e.type === type)
}

export const deserializePromotions = (
  method: PromotionAttr,
  include,
): Promotion => ({
  id: method.id,
  type: method.attributes.type,
  name: method.attributes.name,
  expires_at: method.attributes.expires_at,
  starts_at: method.attributes.starts_at,
  description: method.attributes.description,
  usage_limit: method.attributes.usage_limit,
  match_policy: method.attributes.match_policy,
  code: method.attributes.code,
  advertise: method.attributes.advertise,
  path: method.attributes.path,
  public_metadata: method.attributes.public_metadata,
  private_metadata: method.attributes.private_metadata,
  promotion_action: findAttachment(include, 'promotion_action')?.attributes
    .type,
  preferences: findAttachment(include, 'promotion_rule')?.attributes
    .preferences,
})

export const deserializeOrderPromotions = (
  method,
  include,
): OrderPromotion => ({
  id: method.id,
  order_id: method.relationships.order.data.id,
  promotion_id: method.relationships.promotion.data.id,
  display_amount: method.attributes.display_amount,
})
