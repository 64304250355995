import { getDatetimeString } from '@/api_client/api/serializers/getters'
import { StockLocation } from '@/api_client/types/stock_location'
import { StoreCredit } from '@/api_client/types/store_credit'
import { extractRelationships } from '@/api_client/api/serializers/common'
import { deserializeUser } from '@/api_client/api/serializers/user'

const findInclude = (included, type, id) => {
  return included.find(
    (i) => i.type == type && i.id.toString() == id.toString(),
  )
}

export const deserializeStoreCredit = (data, include): StoreCredit => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    amount: +data.attributes.amount,
    amount_used: +data.attributes.amount_used,
    memo: data.attributes.memo,

    deleted_at: getDatetimeString(data.attributes.deleted_at),
    currency: data.attributes.currency,
    amount_authorized: data.attributes.amount_authorized,
    originator_type: data.attributes.originator_type,
    order_number: data.attributes.order_number,
    originator_id: data.attributes.originator_id,

    created_at: getDatetimeString(data.attributes.created_at),
    updated_at: getDatetimeString(data.attributes.updated_at),
    public_metadata: data.attributes.public_metadata,
    private_metadata: data.attributes.private_metadata,
    expires_at: getDatetimeString(data.attributes.expires_at),
    created_by: deserializeUser(
      findInclude(include, 'user', data.relationships.created_by.data.id),
      [],
    ),
    category: findInclude(
      include,
      'store_credit_category',
      data.relationships.store_credit_category.data.id,
    )?.attributes?.name,
  }
}
