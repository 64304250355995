import Http from '../Http'
import {
  AddItem,
  CouponCode,
  EstimateShippingMethods,
  SetQuantity,
} from '../interfaces/endpoints/CartClass'
import { IEstimatedShippingMethodsResult } from '../interfaces/EstimatedShippingMethod'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  IConsignmentResult,
  IConsignmentsResult,
} from '@/api_client/interfaces/Consignment'
import { IPayoutResult, IPayoutsResult } from '@/api_client/interfaces/Payout'
import { IShipment } from '@/api_client/interfaces/attributes/Shipment'
import updateShipmentTrackNumber from '@/api_client/api/updateShipmentNumber'

export default class Shipments extends Http {
  public async updateShipmentTrackNumber(
    token: IToken,
    id,
    tracking,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentPath(id),
      token,
      {},
      { shipment: { tracking: tracking } },
    )
  }

  public async ship(
    token: IToken,
    id,
    isPlaceDeliveryOrder = false,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentShipPath(id),
      token,
      {},
      { shipment: { place_delivery: isPlaceDeliveryOrder ? '1' : null } },
    )
  }

  public async place(token: IToken, id): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentPlacePath(id),
      token,
    )
  }
}
