class POSHelper {
  public static getSavedOrderIds(): string[] {
    return localStorage.getItem('local_order_ids')?.split(',') || []
  }

  public static removeAllSavedOrderIds() {
    localStorage.removeItem('local_order_ids')
  }

  public static addSavedOrderId(savedOrderIds, id: string): string[] {
    // const savedOrderIds = this.getSavedOrderIds()
    if (!savedOrderIds.includes(id.toString())) {
      savedOrderIds.push(id.toString())
      localStorage.setItem('local_order_ids', savedOrderIds.join(','))
    }
    return savedOrderIds
  }

  public static removeSavedOrderId(savedOrderIds, id: string): string[] {
    // const savedOrderIds = this.getSavedOrderIds()
    if (savedOrderIds.includes(id.toString())) {
      savedOrderIds.forEach((item, index) => {
        if (item === id.toString()) savedOrderIds.splice(index, 1)
      })
      localStorage.setItem('local_order_ids', savedOrderIds.join(','))
    }
    return savedOrderIds
  }

  public static setActiveOrderId(id: string): string {
    // console.log('setActiveOrderId')
    // this.addSavedOrderId(id)
    POSHelper.addSavedOrderId(POSHelper.getSavedOrderIds(), id.toString())
    localStorage.setItem('active_order_id', id.toString())
    return id
  }

  public static getActiveOrderId(): string {
    const savedOrderIds = this.getSavedOrderIds()
    const activeOrderId = localStorage.getItem('active_order_id')
    if (activeOrderId && savedOrderIds.includes(activeOrderId)) {
      return activeOrderId.toString()
    } else {
      return savedOrderIds.length > 0 ? savedOrderIds[0].toString() : null
    }
  }
}

export default POSHelper
