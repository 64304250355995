<template>
  <CCard>
    <CCardHeader>{{ 'Customer' }}</CCardHeader>
    <CCardBody>
      <div v-if="user" style="margin-bottom: 8px">
        <table>
          <tr>
            <td>ID</td>
            <td>{{ user.id }}</td>
          </tr>
          <tr>
            <td>{{ $t('Name') }}</td>
            <td>{{ user.name }}</td>
          </tr>
          <tr>
            <td>{{ $t('Phone') }}</td>
            <td>{{ user.phone }}</td>
          </tr>
          <tr>
            <td>{{ $t('Email') }}</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>{{ $t('Membership') }}</td>
            <td>{{ user.membership }}</td>
          </tr>
          <tr>
            <td>
              {{ $t('Store credit') }}
            </td>
            <td>{{ storeCreditGetters.format(user.store_credit) }}</td>
          </tr>
          <tr>
            <td>{{ $t('Current year value') }}</td>
            <td>{{ filters.currency(user.current_year_value) }}</td>
          </tr>
          <tr>
            <td>{{ $t('Lifetime value') }}</td>
            <td>{{ filters.currency(user.lifetime_value) }}</td>
          </tr>
          <tr style="display: none">
            <td>{{ $t('Code') }}</td>
            <td>{{ user.firebase_uid }}</td>
          </tr>
        </table>
        <!--        <div>ID: {{ user.id }}</div>-->
        <!--        <div>{{ $t('Name') }}: {{ user.name }}</div>-->
        <!--        <div>{{ $t('Phone') }}: {{ user.phone }}</div>-->
        <!--        <div>-->
        <!--          {{ $t('Store credit') }}:-->
        <!--          {{ storeCreditGetters.format(user.store_credit) }}-->
        <!--        </div>-->
      </div>
      <CNav variant="tabs">
        <CNavItem v-for="tab in tabs" v-bind:key="tab">
          <CNavLink :active="isActive(tab)" @click="tabClick(tab)">
            {{ $t(tab.title) }}
          </CNavLink>
        </CNavItem>
      </CNav>
      <router-view v-if="user" :user="user" />
    </CCardBody>
  </CCard>
</template>
<script>
import { AuthHelper } from '@/api_client'
import storeCreditGetters from '@/api_client/getters/storeCreditGetters'

export default {
  name: 'AppUser',
  components: {},
  data() {
    let tabs = [
      { title: 'Info', path: 'info' },
      { title: 'Orders', path: 'orders' },
      { title: 'Consignments', path: 'consignments' },
      { title: 'Store Credits', path: 'credits' },
      { title: 'Payouts', path: 'payouts' },
      { title: 'Payout methods', path: 'payout-methods' },
      { title: 'Reset password', path: 'reset-password' },
      { title: 'Update email', path: 'update-email' },
    ]
    if (AuthHelper.isRoleIn('sadmin')) {
      tabs.push({ title: 'Roles', path: 'roles' })
    }
    return {
      storeCreditGetters,
      user: null,
      loadedOrders: null,
      tabs,
      currentTab: this.$route.path,
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    if (!AuthHelper.guard(this.$route.name)) {
      return
    }
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',
  },
  methods: {
    isActive(tab) {
      if (tab.path === 'info' && this.currentTab.split('/').length === 3) {
        return true
      } else {
        return this.currentTab.includes(tab.path)
      }
    },
    tabClick(newTab) {
      const tabPath = newTab
      const prePath = this.$route.path.substring(
        0,
        this.$route.path.lastIndexOf('/' + this.$route.params.id) +
          ('/' + this.$route.params.id).length,
      )
      const subfix = tabPath === this.tabs[0].path ? '' : '/' + tabPath.path
      this.currentTab = prePath + subfix
      this.$router.push({
        path: prePath + subfix,
        query: {},
      })
    },
    fetchData() {
      this.apiClient.getCustomer(this.$route.params.id).then((data) => {
        this.user = data
      })
    },
    newClick() {
      this.visibleUserModel = true
    },
  },
}
</script>
