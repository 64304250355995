import axios from 'axios'
import { IOAuthToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { ApiConfig, ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import getUsers from '@/api_client/api/getUsers'

async function associateCart(
  config: ApiConfig,
  guestCartToken: string,
  bearerToken: IOAuthToken,
) {
  await axios.patch(
    `${config.backendUrl}/api/v2/storefront/cart/associate?guest_order_token=${guestCartToken}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${bearerToken.access_token}`,
      },
    },
  )
}

export default async function logIn(
  { client, config }: ApiContext,
  params,
): Promise<void> {
  const { username, password } = params

  const response = await client.authentication.getToken({
    username: username,
    password: password,
  })
  if (response.isSuccess()) {
    const bearerToken = response.success()
    await AuthHelper.changeOAuthToken(bearerToken, username)
    await AuthHelper.getUserInfo(client, bearerToken.access_token)
  } else {
    throw response.fail()
  }
}
