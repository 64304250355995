import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializePaymentMethods } from '../serializers/payment'
import { deserializePromotions } from '@/api_client/api/serializers/promotion'

export default async function getPromotions({ client, config }: ApiContext) {
  const token = await getCurrentBearerOrCartToken({ client, config })
  const result = await client.checkout.promotions(token, {
    include: '',
    filter: { promotion_category_id_eq: 2 }, // admin only
  })

  if (result.isSuccess()) {
    const payload = result.success()
    const included = payload.included
    // console.log(payload)
    const r = payload.data.map((d) => deserializePromotions(d, included))
    // console.log(r)
    return r
  } else {
    throw result.fail()
  }
}
