import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CCloseButton = _resolveComponent("CCloseButton")!
  const _component_CSidebarHeader = _resolveComponent("CSidebarHeader")!
  const _component_CSidebar = _resolveComponent("CSidebar")!

  return (_openBlock(), _createBlock(_component_CSidebar, {
    colorScheme: "light",
    selfHiding: "xxl",
    size: "lg",
    overlaid: "",
    placement: "end",
    visible: $setup.asideVisible
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CSidebarHeader, { class: "bg-transparent p-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_CCloseButton, {
            class: "float-end p-4",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleAside')))
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}