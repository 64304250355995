import { ApiConfig, ApiContext, Consignment } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'
import { deserializeConsignment } from '@/api_client/api/serializers/consignment'

export default async function saveConsignment(
  { client, config }: ApiContext,
  body = {},
  consignment_id = null,
): Promise<any> {
  const bearerToken = await getCurrentBearerToken({ client, config })
  let result
  const include =
    'user,consignment_line_items,consignment_line_items.variant,consignment_line_items.variant.images,consignment_line_items.discounts'
  if (consignment_id) {
    result = await client.consignments.update(
      { bearerToken },
      consignment_id,
      {
        include,
      },
      { ...body, lineItems: '' },
    )
  } else {
    result = await client.consignments.create(
      { bearerToken },
      {
        consignment: body,
        include,
      },
    )
  }

  if (result.isSuccess()) {
    const data = result.success()
    if (consignment_id == null) {
      return deserializeConsignment(data.data, data.included, config)
    } else {
      return true
    }
  } else {
    console.log(result.fail())
    throw result.fail()
  }
}
