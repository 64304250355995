import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToTaxonImages,
  deserializeCategories,
} from '@/api_client/api/serializers/category'
import { deserializeTaxons } from '@/api_client/api/serializers/taxon'

export default async function getTaxons(
  { client, config }: ApiContext,
  permalink_in = '',
  nameKeyword = '',
  parentPermalink = '',
  taxonId = '',
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.taxons.list(
    { bearerToken },
    {
      filter: {
        id_eq: taxonId,
        permalink_in: permalink_in.split(','),
        name_cont: nameKeyword,
        parent_permalink: parentPermalink,
        depth_eq: parentPermalink.length == 0 ? '' : '1',
      },
      include: parentPermalink.length == 0 ? 'children,,children.children' : '',
      per_page: 20,
    },
  )
  if (response.isSuccess()) {
    const payload = response.success()
    const t = deserializeTaxons(payload.data, payload.included)
    return t
  } else {
    console.log(response.fail())
    return null
  }
}
