import { ApiContext } from '../../types'

export default async function getAreas({ client }: ApiContext, { parentArea }) {
  console.log(parentArea)
  const result = await client.countries.areaSearch({ parent_area: parentArea })
  if (result.isSuccess()) {
    const payload = result.success()
    const r = payload.data
    r.sort((i, y) => (i.name > y.name ? 1 : -1))
    return r
  } else {
    throw result.fail()
  }
}
