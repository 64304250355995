import Http from '../Http'
import { ICountriesResult, ICountryResult } from '../interfaces/Country'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'
import { IStatesResult } from '@/api_client/interfaces/State'
import { storefrontPath } from '@spree/storefront-api-v2-sdk/src/routes'

export default class Countries extends Http {
  public async list(): Promise<ICountriesResult> {
    return (await this.spreeResponse(
      'get',
      Routes.countriesPath(),
    )) as ICountriesResult
  }

  public async show(iso: string, params: IQuery = {}): Promise<ICountryResult> {
    return (await this.spreeResponse(
      'get',
      Routes.countryPath(iso),
      {},
      params,
    )) as ICountryResult
  }

  public async areaSearch(params: IQuery = {}): Promise<IStatesResult> {
    return (await this.spreeResponse(
      'get',
      `${storefrontPath}/countries/global`,
      {},
      params,
    )) as IStatesResult
  }
}
