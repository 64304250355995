import { ApiContext, Cart, LineItem } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart, deserializeLineItem } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { addHostToIncluded } from '@/api_client/api/serializers/product'
import { deserializePayout } from '@/api_client/api/serializers/payout'

// old
export default async function removeAllPayoutItems(
  { client, config }: ApiContext,
  payoutId,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.payouts.removeAllItems(
      { bearerToken: token },
      payoutId,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)
      return deserializePayout(payload.data, payload.included, config)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
