import { getDatetimeString } from '@/api_client/api/serializers/getters'
import { StockLocation } from '@/api_client/types/stock_location'

export const deserializeStockLocation = (data, include): StockLocation => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    name: data.attributes.name,
    default: data.attributes.default,
    active: data.attributes.active,
    created_at: getDatetimeString(data.attributes.created_at),
    updated_at: getDatetimeString(data.attributes.updated_at),
    areas: include
      .filter(
        (d) => d.type == 'stock_location_area' && d.attributes.name[0] == 'W',
      )
      .map((a) => deserializeStockLocationArea(a))
      .sort((a, b) => {
        if (a.name[0] == b.name[0]) {
          return +a.name.substring(1) - +b.name.substring(1)
        } else {
          return a.name[0] > b.name[0] ? 1 : -1
        }
      }),
  }
}

export const deserializeStockLocationArea = (data): any => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    name: data.attributes.name,
    position: data.attributes.position,
    item_count: data.attributes.item_count,
    stock_location_id: data.attributes.stock_location_id,
    created_at: getDatetimeString(data.attributes.created_at),
    updated_at: getDatetimeString(data.attributes.updated_at),
  }
}
