import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CBreadcrumbItem = _resolveComponent("CBreadcrumbItem")!
  const _component_CBreadcrumb = _resolveComponent("CBreadcrumb")!

  return (_openBlock(), _createBlock(_component_CBreadcrumb, { class: "d-md-down-none me-auto mb-0" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbs, (item) => {
        return (_openBlock(), _createBlock(_component_CBreadcrumbItem, {
          href: item.active ? '' : item.path,
          active: item.active,
          key: item
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["href", "active"]))
      }), 128))
    ]),
    _: 1
  }))
}