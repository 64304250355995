import type { ApiContext, GetProductsParams } from '../../types'
import {
  addHostToProductImages,
  deserializeLimitedVariants,
  deserializeProducts,
} from '../serializers/product'
import { deserializeSearchMetadata } from '@/api_client/api/serializers/search'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function getProducts(
  { client, config }: ApiContext,
  params,
  { keyword = '', showTaxons = true, showStockItems = true },
) {
  try {
    let include = 'default_variant,images'
    if (showTaxons) {
      include += ',taxons'
    }

    if (showStockItems) {
      include += ',default_variant.stock_items'
    }
    console.log(params)
    const filter = {
      ...params.filter,
      in_stock_eq: params.filter?.in_stock,
    }
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.products.list(
      { bearerToken: token },
      {
        filter,
        include,
        page: params.page,
        sort: params.sort,
        per_page: params.per_page || 10,
      },
    )

    if (result.isSuccess()) {
      const data = result.success()
      // console.log('data')
      // console.log(data)
      const productsData = addHostToProductImages(data, config)

      return {
        data: deserializeProducts(productsData, productsData.included),
        meta: data.meta,
        keyword: keyword,
      }
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
