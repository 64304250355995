import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeRole } from '@/api_client/api/serializers/user'

export default async function getRoles({ client, config }: ApiContext) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.users.roles({ bearerToken })
  if (response.isSuccess()) {
    const data = response.success().data
    return data.map((d) => deserializeRole(d))
  } else {
    return []
  }
}
