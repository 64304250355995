import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { CoreuiVue } from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import ScClient from '@/api_client/ScClient'
import vSelect from 'vue-select'
import { initializeApp } from 'firebase/app'
import filters from '@/helpers/filters'
import money from 'v-money3'
import { createI18n } from 'vue-i18n'
import { messages } from '@/i18n/messags'
import CoreuiVue from '@coreui/vue-pro'
import { AuthHelper } from '@/api_client'

const firebaseConfig = {
  apiKey: 'AIzaSyDucqGRd-On829RIne4XUAzcn8LLYqY07Y',
  authDomain: 'second-corner.firebaseapp.com',
  projectId: 'second-corner',
  storageBucket: 'second-corner.appspot.com',
  messagingSenderId: '989917059759',
  appId: '1:989917059759:web:b5d46822efe3d2169cd25a',
  measurementId: 'G-13QRQ6LY46',
}

// const messages = {
//   en: {
//     hello: 'hello world',
//   },
//   vi: {
//     hello: 'H',
//   },
// }

const lang = localStorage.getItem('lang') || 'vi'
const i18n = createI18n({
  // something vue-i18n options here ...
  locale: 'vi', // set locale
  // fallbackLocale: 'en', // set fallback locale
  messages,
  silentTranslationWarn: lang == 'en',
  silentFallbackWarn: true,
})

// const currentRole = AuthHelper.isRoleIn('sadmin')

function isRoleIn(string) {
  return AuthHelper.isRoleIn(string)
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const app = createApp(App)
app.config.globalProperties.apiClient = new ScClient()
app.config.globalProperties.filters = filters
app.config.globalProperties.firebase = initializeApp(firebaseConfig)
app.config.globalProperties.capitalize = capitalizeFirstLetter
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(money)
app.use(i18n)

// Vue.use(Vue2Filters)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('v-select', vSelect)

app.mount('#app')
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.global.locale = lang
