import { ApiContext, Image, Taxon } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeTaxons } from '@/api_client/api/serializers/taxon'

export default async function addTaxon(
  { client, config }: ApiContext,
  parentPermalink,
  name,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.taxons.create(
      { bearerToken: token },
      { taxon: { name: name, parent_permalink: parentPermalink } },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      console.log(payload)
      const t = deserializeTaxons([payload.data], [])
      return t[0]
    } else {
      return null
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
