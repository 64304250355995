import { deserializeAddress } from '@/api_client/api/serializers/address'
import { PayoutMethod, Role, User } from '@/api_client/types/user'

const findAddress = (attachments: any[], id: string) => {
  return attachments.find(
    (e) => e.id === id && ['address', 'sc_address'].includes(e.type),
  )
}

const findPayoutMethod = (attachments: any[], id: string) => {
  return attachments.find(
    (e) => e.id === id && ['payout_method'].includes(e.type),
  )
}

const findRole = (attachments: any[], id: string) => {
  return attachments.find((e) => e.id === id && ['role'].includes(e.type))
}

export const deserializeUser = (apiUser, included: any[]): User => {
  if (apiUser == null) {
    return null
  }
  let address
  if (
    apiUser?.relationships?.ship_address?.data &&
    included != null &&
    included.length > 0
  ) {
    address = deserializeAddress(
      findAddress(included, apiUser.relationships.ship_address.data.id),
      apiUser.attributes.name,
      apiUser.attributes.phone,
    )
  } else {
    address = {}
  }
  // const address = findAddress(included,apiUser.relationships.ship_address.data?.id)included.find()
  // console.log(apiUser)
  // console.log(apiUser.attributes)
  // console.log('deserializeUser')
  // console.log(apiUser)
  // console.log(included)
  return {
    address: address,
    name: apiUser.attributes.name || '',
    phone: apiUser.attributes.phone || '',
    email: apiUser.attributes.email || '',
    fb_name: apiUser.attributes.fb_name || '',
    dob: apiUser.attributes.dob || '',
    gender: apiUser.attributes.gender || 'female',
    customer_type: apiUser.attributes.customer_type || 'individual',
    tax_id: apiUser.attributes.tax_id || '',
    notes: apiUser.attributes.notes || '',
    membership: apiUser.attributes.membership || '?',
    firebase_uid: apiUser.attributes.firebase_uid || '',
    store_credit: +apiUser.attributes.total_available_store_credit || 0,
    current_year_value: +apiUser.attributes.current_year_value || 0,
    lifetime_value: +apiUser.attributes.lifetime_value || 0,
    payout_methods:
      included == null
        ? []
        : apiUser.relationships?.payout_methods?.data.map((d) =>
            deserializePayoutMethod(findPayoutMethod(included, d.id)),
          ),
    roles:
      included == null
        ? []
        : apiUser.relationships?.spree_roles?.data.map((d) =>
            deserializeRole(findRole(included, d.id)),
          ),
    id: apiUser.id,
  }
}

export const deserializePayoutMethod = (apiPayoutMethod): PayoutMethod => {
  if (apiPayoutMethod == null) {
    return null
  }

  return {
    id: apiPayoutMethod.id,
    user_id: apiPayoutMethod.attributes.user_id,
    payout_method: apiPayoutMethod.attributes.payout_method,
    payout_company_name: apiPayoutMethod.attributes.payout_company_name,
    account_number: apiPayoutMethod.attributes.account_number,
    account_name: apiPayoutMethod.attributes.account_name,
    is_primary: apiPayoutMethod.attributes.is_primary,
    created_at: apiPayoutMethod.attributes.created_at,
    updated_at: apiPayoutMethod.attributes.updated_at,
  }
}

export const deserializeRole = (apiPayoutMethod): Role => {
  if (apiPayoutMethod == null) {
    return null
  }

  return {
    id: apiPayoutMethod.id,
    name: apiPayoutMethod.attributes.name,
  }
}
