import { ApiContext } from '../../types'
import { deserializeCountry } from '../serializers/country'

export default async function getAvailableCountries(
  { client }: ApiContext,
  { iso },
) {
  const result = await client.countries.show(iso, { include: 'states' })
  if (result.isSuccess()) {
    const includedStates = result.success().included
    const apiCountry = result.success().data
    const r = deserializeCountry(apiCountry, includedStates)
    r.children.sort((i, y) => (i.name > y.name ? 1 : -1))
    r.children.sort((i) => (i.name === 'Thành phố Hà Nội' ? -1 : 1))
    r.children.sort((i) => (i.name === 'Thành phố Hồ Chí Minh' ? -1 : 1))
    return r
  } else {
    throw result.fail()
  }
}
