import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  ICmsPageResult,
  ICmsPages,
  ICmsPagesResult,
} from '@/api_client/interfaces/CmsPage'

export default class CmsPages extends Http {
  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<ICmsPagesResult> {
    return (await this.spreeResponse(
      'get',
      Routes.cmsPagesPath(),
      token,
      params,
    )) as ICmsPagesResult
  }

  public async show(
    token: IToken,
    id,
    params: IQuery = {},
  ): Promise<ICmsPageResult> {
    return (await this.spreeResponse(
      'get',
      Routes.cmsPagePath(id),
      token,
      params,
    )) as ICmsPageResult
  }
}
