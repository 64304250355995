import Http from '../Http'
import {
  AddItem,
  CouponCode,
  EstimateShippingMethods,
  SetQuantity,
} from '../interfaces/endpoints/CartClass'
import { IEstimatedShippingMethodsResult } from '../interfaces/EstimatedShippingMethod'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  IConsignmentResult,
  IConsignmentsResult,
} from '@/api_client/interfaces/Consignment'
import { IPayoutResult, IPayoutsResult } from '@/api_client/interfaces/Payout'

export default class Payouts extends Http {
  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<IPayoutsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.payoutsPath(),
      token,
      params,
    )) as IPayoutsResult
  }

  public async show(
    token: IToken,
    payoutId,
    params: IQuery = {},
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'get',
      Routes.payoutPath(payoutId),
      token,
      params,
    )) as IPayoutResult
  }

  public async refreshItems(token: IToken, payoutId): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'get',
      Routes.refreshPayoutPath(payoutId),
      token,
    )) as IPayoutResult
  }

  public async addPayoutItem(
    token: IToken,
    payoutId,
    params: IQuery = {},
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'post',
      Routes.addPayoutItemPath(payoutId),
      token,
      params,
    )) as IPayoutResult
  }

  public async addPayoutItemsByConsignmentId(
    token: IToken,
    payoutId,
    consignmentId,
    isIncludeQuantity = false,
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'post',
      Routes.addPayoutItemsByConsignmentIdPath(payoutId),
      token,
      {
        payout: {
          consignment_id: consignmentId,
          is_include_quantity: isIncludeQuantity ? '1' : '',
        },
      },
    )) as IPayoutResult
  }

  public async removeAllItems(token: IToken, payoutId): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.removeAllItemsPath(payoutId),
      token,
    )) as IPayoutResult
  }

  public async updateState(
    token: IToken,
    payoutId,
    data,
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.updatePayoutStatePath(payoutId),
      token,
      {},
      data,
    )) as IPayoutResult
  }

  public async delete(token: IToken, orderId: number): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.payoutPath(orderId),
      token,
    )) as IPayoutResult
  }

  public async create(
    token?: IToken,
    params: IQuery = {},
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'post',
      Routes.payoutsPath(),
      token,
      params,
    )) as IPayoutResult
  }

  public async update(
    token: IToken,
    orderId: number,
    params: any,
    data: any,
  ): Promise<IPayoutResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.payoutPath(orderId),
      token,
      params,
      data,
    )) as IPayoutResult
  }

  // public async addItems(
  //   token: IToken,
  //   consignmentId: number,
  //   params: any,
  // ): Promise<IConsignmentResult> {
  //   return (await this.spreeResponse(
  //     'post',
  //     Routes.consignmentAddItemsPath(consignmentId),
  //     token,
  //     params,
  //   )) as IConsignmentResult
  // }

  // public async updateItem(
  //   token: IToken,
  //   id: string,
  //   lineItemId: number,
  //   params: any,
  //   data: any,
  // ): Promise<any> {
  //   return await this.spreeResponse(
  //     'patch',
  //     Routes.consignmentLineItemsSavePath(id, lineItemId),
  //     token,
  //     params,
  //     data,
  //   )
  // }
  //

  public async removeItem(
    token: IToken,
    id: string,
    itemId: number,
  ): Promise<any> {
    return await this.spreeResponse(
      'delete',
      Routes.payoutItemsPath(id, itemId),
      token,
      {},
    )
  }
}
