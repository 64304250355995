import { ApiConfig, ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'

export default async function saveUser(
  { client, config }: ApiContext,
  body = {},
  user_id = null,
): Promise<User> {
  const bearerToken = await getCurrentBearerToken({ client, config })
  let result
  if (user_id) {
    // update
    result = await client.account.update({ bearerToken }, user_id, {
      user: body,
    })
  } else {
    // create
    result = await client.account.create(
      { bearerToken },
      {
        user: { ...body },
        include: 'ship_address',
      },
    )
  }

  if (result.isSuccess()) {
    const data = result.success()
    return deserializeUser(data.data, data.included)
  } else {
    console.log(result.fail())
    throw result.fail()
  }
}
