import { authParams, refreshParams } from '../helpers/auth'
import Http from '../Http'
import { AuthTokenAttr, RefreshTokenAttr } from '../interfaces/Authentication'
import { IOAuthTokenResult } from '../interfaces/Token'
import { Routes } from '../routes'

export interface IdTokenAttr {
  // eslint-disable-next-line camelcase
  firebase_id_token: string
  // eslint-disable-next-line camelcase
  grant_type: string
}

export interface ForgotPasswordAttr {
  email: string
}

export default class Authentication extends Http {
  public async getToken(params: AuthTokenAttr): Promise<IOAuthTokenResult> {
    return (await this.spreeResponse(
      'post',
      Routes.oauthTokenPath(),
      {},
      authParams(params),
    )) as IOAuthTokenResult
  }

  public async refreshToken(
    params: RefreshTokenAttr,
  ): Promise<IOAuthTokenResult> {
    return (await this.spreeResponse(
      'post',
      Routes.oauthTokenPath(),
      {},
      refreshParams(params),
    )) as IOAuthTokenResult
  }

  public async getTokenByFirebase(
    params: IdTokenAttr,
  ): Promise<IOAuthTokenResult> {
    return (await this.spreeResponse(
      'post',
      Routes.oauthTokenPath(),
      {},
      params,
    )) as IOAuthTokenResult
  }
}
