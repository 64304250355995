
import avatar from './../assets/images/avatars/8.jpg'

export default {
  name: 'AppHeaderDropdownAccnt',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    logout() {
      this.apiClient.logout()
      location.href = '/#/login'
      // location.replace('/#/login')
      // location.reload()
      // this.$router.push('/#/login')
    },
  },
}
