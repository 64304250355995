// import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships';

export const deserializeState = (apiState) => ({
  id: apiState.id,
  code: apiState.attributes.abbr,
  name: apiState.attributes.name,
  parent_id: apiState.attributes.parent_id,
})

export const deserializeCountry = (apiCountry, includedStates) => ({
  id: apiCountry.iso,
  key: apiCountry.attributes.iso,
  label: apiCountry.attributes.name,
  stateRequired: apiCountry.attributes.state_required,
  postalCodeRequired: apiCountry.attributes.zipcode_required,
  isDefault: apiCountry.attributes.default,
  // states: includedStates.filter(e => e.id === '29').map(deserializeState)
  children: includedStates.map(deserializeState),
  // .filter(e => e.type === 'line_item' && (lineItemData as RelationType[]).find(d => d.id === e.id) !== undefined);
})
