import { Address, ApiContext } from '../../types'
// import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { serializeAddress } from '../serializers/address'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
// import getCurrentBearerToken from "@/api_client/api/authentication/getCurrentBearerToken";

export default async function addPayment(
  { client, config }: ApiContext,
  orderId,
  paymentMethodId,
  amount,
  state = 'completed',
): Promise<void> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  // const serializedAddress = serializeAddress(params)
  const result = await client.payment.createPayment(
    { bearerToken },
    {
      payment: {
        order_id: orderId,
        payment_method_id: paymentMethodId,
        amount: amount,
        state: state,
      },
    },
  )

  if (result.isFail()) {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
