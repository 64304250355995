import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToTaxonImages,
  deserializeCategories,
} from '@/api_client/api/serializers/category'
import { deserializeTaxons } from '@/api_client/api/serializers/taxon'

export default async function getTaxon(
  { client, config }: ApiContext,
  taxonId = '',
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.taxons.show({ bearerToken }, taxonId)
  if (response.isSuccess()) {
    const payload = response.success()
    // console.log(payload)
    // console.log('payload.data')
    // console.log(payload.data)
    const t = deserializeTaxons([payload.data], payload.included || [])
    return t[0]
  } else {
    console.log(response.fail())
    return null
  }
}
