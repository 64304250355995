import Http from '../Http'
import {
  AddItem,
  CouponCode,
  EstimateShippingMethods,
  SetQuantity,
} from '../interfaces/endpoints/CartClass'
import { IEstimatedShippingMethodsResult } from '../interfaces/EstimatedShippingMethod'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  IConsignmentResult,
  IConsignmentsResult,
} from '@/api_client/interfaces/Consignment'
import {
  IImageResult,
  IImages,
  IImagesResult,
} from '@/api_client/interfaces/Image'

export default class Images extends Http {
  // public async list(
  //   token: IToken,
  //   params: IQuery = {},
  // ): Promise<IImagesResult> {
  //   return (await this.spreeResponse(
  //     'get',
  //     Routes.imagesPath(),
  //     token,
  //     params,
  //   )) as IImagesResult
  // }

  public async delete(token: IToken, orderId: number): Promise<IImageResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.imagePath(orderId),
      token,
    )) as IImageResult
  }

  public async create(token: IToken, data: FormData): Promise<IImageResult> {
    return (await this.spreeResponse(
      'post',
      Routes.imagesPath(),
      token,
      null,
      data,
    )) as IImageResult
  }

  public async update(
    token: IToken,
    id: number,
    params: any,
  ): Promise<IImageResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.imagePath(id),
      token,
      params,
    )) as IImageResult
  }

  //
  // public async addItems(
  //   token: IToken,
  //   consignmentId: number,
  //   params: any,
  // ): Promise<IConsignmentResult> {
  //   return (await this.spreeResponse(
  //     'post',
  //     Routes.consignmentAddItemsPath(consignmentId),
  //     token,
  //     params,
  //   )) as IConsignmentResult
  // }
  //
  // public async updateItem(
  //   token: IToken,
  //   id: string,
  //   lineItemId: number,
  //   params: any,
  // ): Promise<any> {
  //   return await this.spreeResponse(
  //     'patch',
  //     Routes.consignmentLineItemsSavePath(id, lineItemId),
  //     token,
  //     params,
  //   )
  // }
  //
  // public async removeItem(
  //   token: IToken,
  //   id: string,
  //   lineItemId: number,
  // ): Promise<any> {
  //   return await this.spreeResponse(
  //     'delete',
  //     Routes.consignmentLineItemsSavePath(id, lineItemId),
  //     token,
  //     {},
  //   )
  // }
}
