import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializePaymentMethods } from '../serializers/payment'

export default async function getPaymentMethods(
  { client, config }: ApiContext,
  isShowStoreCredit = false,
) {
  const token = await getCurrentBearerOrCartToken({ client, config })
  const result = await client.checkout.paymentMethods(token, {
    filter: { display_on: 'both' },
  })

  if (result.isSuccess()) {
    const payload = result.success()
    return payload.data
      .filter((d) => isShowStoreCredit || d.id.toString() !== '3')
      .map(deserializePaymentMethods)
      .sort((a, b) => (a.id.toString() == '4' ? -1 : 1))
  } else {
    throw result.fail()
  }
}
