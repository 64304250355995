const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
})

const filters = {
  currency(input: number) {
    if (isNaN(input)) {
      input = 0
    }
    return formatter.format(input)
    // return '$' + input.toFixed(2)
  },
}
export default filters
