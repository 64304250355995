import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToTaxonImages,
  deserializeCategories,
} from '@/api_client/api/serializers/category'

export default async function refreshPayoutItems(
  { client, config }: ApiContext,
  payout_id,
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.payouts.refreshItems({ bearerToken }, payout_id)
  if (response.isSuccess()) {
    const payload = response.success()
    // if (payload.data[0].attributes.is_leaf) {
    //   // console.log('true')
    // } else {
    //   // console.log('false')
    // }
    // // console.log(payload)
    // const t = deserializeCategories(payload.data, payload.included)
    // // console.log(t)
    return true
  } else {
    console.log(response.fail())
    return false
  }
}
