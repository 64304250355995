import type {
  JsonApiDocument,
  JsonApiResponse,
} from '@spree/storefront-api-v2-sdk/types/interfaces/JsonApi'
import type {
  IProduct,
  IProducts,
} from '@spree/storefront-api-v2-sdk/types/interfaces/Product'
import type { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import type {
  ApiConfig,
  ProductVariant,
  OptionType,
  OptionValue,
  Image,
  Taxon,
  Order,
  StockItem,
} from '../../types'
import { extractRelationships, filterAttachments } from './common'
import { Cart, Measurement, Property } from '../../types'
import {
  getDateString,
  getDatetimeString,
} from '@/api_client/api/serializers/getters'
import {
  deserializeStockLocation,
  deserializeStockLocationArea,
} from '@/api_client/api/serializers/stock_location'

const groupIncluded = <Groups extends keyof any>(
  included,
  discriminators,
): { [key in Groups]: JsonApiDocument[] } => {
  const discriminatorsKeys = Object.keys(discriminators)

  const emptyGroups = discriminatorsKeys.reduce(
    (accumulatedGroups, discriminatorKey) => {
      accumulatedGroups[discriminatorKey] = []

      return accumulatedGroups
    },
    {},
  )

  const filledGroups = included?.reduce((accumulatedGroups, document) => {
    return discriminatorsKeys.reduce(
      (discriminatedGroups, discriminatorKey) => {
        if (discriminators[discriminatorKey](document)) {
          return {
            ...discriminatedGroups,
            [discriminatorKey]: [
              ...discriminatedGroups[discriminatorKey],
              document,
            ],
          }
        }

        return discriminatedGroups
      },
      accumulatedGroups,
    )
  }, emptyGroups)

  return filledGroups
}

const isVariantOfProduct = (document, productId) =>
  document.type === 'variant' &&
  document.relationships.product.data.id === productId

export const deserializeImages = (
  included: JsonApiDocument[],
  documents: JsonApiDocument[],
): Image[] => {
  const flattenedImageIdentifiers = documents.reduce<RelationType[]>(
    (collectedImageDocuments, imageDocument) => [
      ...collectedImageDocuments,
      ...imageDocument.relationships.images.data,
    ],
    [],
  )

  const uniqueImageIdentifiers = flattenedImageIdentifiers.reduce<
    RelationType[]
  >((collectedImageDocuments, imageDocumentRelationship) => {
    if (
      collectedImageDocuments.some(
        (maybeSameImageDocument) =>
          maybeSameImageDocument.id === imageDocumentRelationship.id,
      )
    ) {
      return collectedImageDocuments
    }

    return [...collectedImageDocuments, imageDocumentRelationship]
  }, [])

  const imageIdentifiersIds = uniqueImageIdentifiers.map(
    (imageDocumentRelationship) => imageDocumentRelationship.id,
  )

  const imageDocuments = filterAttachments(
    included,
    'image',
    imageIdentifiersIds,
  )

  const sortedImageDocuments = imageDocuments.sort((image1, image2) =>
    Math.sign(image1.attributes.position - image2.attributes.position),
  )

  return sortedImageDocuments.map((image) => ({
    id: parseInt(image.id, 10),
    original_url: image.attributes.original_url,
    styles: image.attributes.styles.map((style) => ({
      url: style.url,
      width: parseInt(style.width, 10),
      height: parseInt(style.height, 10),
    })),
    position: image.attributes.position,
  }))
}

const findGenuine = (included, product) => {
  const properties = extractRelationships(
    included,
    'product_property',
    'product_properties',
    product,
  )

  return properties.map((property) => ({
    name: property.attributes.description,
    value: property.attributes.value,
  }))
}

const deserializeProperties = (included, product) => {
  const properties = extractRelationships(
    included,
    'product_property',
    'product_properties',
    product,
  )

  return properties.map((property) => ({
    name: property.attributes.description,
    value: property.attributes.value,
  }))
}

const deserializeOptionTypes = (included, product): OptionType[] => {
  const optionTypes = extractRelationships(
    included,
    'option_type',
    'option_types',
    product,
  )

  return optionTypes.map((optionType) => ({
    id: parseInt(optionType.id, 10),
    type: optionType.type,
    name: optionType.attributes.name,
    position: optionType.attributes.position,
    presentation: optionType.attributes.presentation,
  }))
}

const findTaxonByPermalinkPrefix = (taxons: Taxon[], prefix): Taxon => {
  return taxons.find((t) => t?.permalink?.indexOf(prefix + '/') == 0)
}
const findTaxonIdsByPermalinkPrefix = (taxons: Taxon[], prefix): number[] => {
  const items = taxons.filter((t) => t?.permalink?.includes(prefix + '/'))
  return items.map((i) => i.id)
}

const deserializeTaxons = (included, product): Taxon[] => {
  const taxons = extractRelationships(included, 'taxon', 'taxons', product)

  return taxons.map((optionType) => ({
    id: parseInt(optionType.id, 10),
    name: optionType.attributes.name,
    pretty_name: optionType.attributes.pretty_name,
    permalink: optionType.attributes.permalink,
    seo_title: optionType.attributes.seo_title,
    description: optionType.attributes.description,
    meta_title: optionType.attributes.meta_title,
    meta_description: optionType.attributes.meta_description,
    meta_keywords: optionType.attributes.meta_keywords,
    left: optionType.attributes.left,
    right: optionType.attributes.right,
    position: optionType.attributes.position,
    depth: optionType.attributes.depth,
    updated_at: optionType.attributes.updated_at,
    is_root: optionType.attributes.is_root,
    is_child: optionType.attributes.is_child,
    is_leaf: optionType.attributes.is_leaf,
  }))
}

const deserializeLastOrder = (included, product): any => {
  const orders = extractRelationships(included, 'order', 'orders', product)

  if (orders.length > 0) {
    const completedOrder = orders.find((o) => o.attributes.completed_at != null)
    if (completedOrder) {
      return { id: completedOrder.id, number: completedOrder.attributes.number }
    }
  }
  return { id: null, number: null }
}

const deserializeOptionValues = (included, variant): OptionValue[] => {
  const optionValues = extractRelationships(
    included,
    'option_value',
    'option_values',
    variant,
  )

  return optionValues.map((optionValue) => ({
    id: parseInt(optionValue.id, 10),
    type: optionValue.attributes.type,
    name: optionValue.attributes.name,
    position: optionValue.attributes.position,
    presentation: optionValue.attributes.presentation,
    optionTypeId: parseInt(optionValue.relationships.option_type.data.id, 10),
  }))
}

const buildBreadcrumbs = (included, product) => {
  const taxons = extractRelationships(included, 'taxon', 'taxons', product)
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
  ]

  const addTaxonToBreadcrumbs = (item) => {
    if (item === undefined) {
      return
    }
    const parentId = item.relationships.parent?.data?.id
    const parent = parentId
      ? filterAttachments(included, 'taxon', parentId)[0]
      : undefined
    if (parent) addTaxonToBreadcrumbs(parent)

    breadcrumbs.push({
      text: item.attributes.name,
      link: `/c/${item.attributes.permalink}`,
    })
  }

  addTaxonToBreadcrumbs(taxons.find((t) => t.attributes.name === 'women'))

  breadcrumbs.push({
    text: product.attributes.name,
    link: product.attributes.slug,
  })

  return breadcrumbs
}

const deserializeMeasurements = (
  attachments: JsonApiDocument[],
): Measurement[] => {
  const propertyValue = attachments.filter((a) => a.type == 'product_property')
  const properties = attachments.filter((a) => a.type == 'property')
  const defaultNames = [
    'bust',
    'length',
    'hips',
    'waist',
    'inseam',
    'rise',
    'width',
    'height',
    'heel',
  ]
  return defaultNames.map((property_name) => {
    const currentProperty = properties.find(
      (p) => p.attributes.name.toLowerCase() === property_name,
    )
    if (currentProperty) {
      // console.log(currentProperty.id)
      // console.log(propertyValue)
      const existingValue = propertyValue.find(
        (pv) =>
          pv.attributes.property_id.toString() == currentProperty.id.toString(),
      )
      return {
        id: existingValue.id,
        property_name,
        value: existingValue.attributes.value,
      }
    } else {
      return {
        id: null,
        property_name,
        value: null,
      }
    }
  })
}

export const partialDeserializeProductVariant = (
  product,
  variant,
  attachments: JsonApiDocument[],
): Omit<ProductVariant, 'images'> => {
  const taxons = deserializeTaxons(attachments, product)
  return {
    _id: product.id,
    id: product.id,
    _productId: product.id,
    _variantId: variant.id,
    _description:
      variant.attributes.description || product.attributes.description,
    taxons: taxons,
    raw_taxons: product.relationships.taxons.data.map((taxon) => taxon.id),
    brand_id: findTaxonByPermalinkPrefix(taxons, 'brands')?.id,
    category_id: findTaxonByPermalinkPrefix(taxons, 'women')?.id,
    condition_id: findTaxonByPermalinkPrefix(taxons, 'conditions')?.id,
    color_id: findTaxonByPermalinkPrefix(taxons, 'colors')?.id,
    size_id: findTaxonByPermalinkPrefix(taxons, 'sizes')?.id,
    sc_size_id: findTaxonByPermalinkPrefix(taxons, 'sc-sizes')?.id,
    material_id: findTaxonByPermalinkPrefix(taxons, 'materials')?.id,
    discount_id: findTaxonByPermalinkPrefix(taxons, 'discounts')?.id,
    pattern_ids: findTaxonIdsByPermalinkPrefix(taxons, 'patterns'),
    style_ids: findTaxonIdsByPermalinkPrefix(taxons, 'styles'),
    is_genuine: true,
    description:
      variant.attributes.description || product.attributes.description,
    _categoriesRef: product.relationships.taxons.data.map((taxon) => taxon.id),
    name: product.attributes.name,
    slug: product.attributes.slug,
    product_id: product.attributes.product_id,
    sku: product.attributes.sku || variant.attributes.sku,
    addedToCartAt: product.attributes.added_to_cart_at,
    measurements: deserializeMeasurements(attachments),
    product_discounts: deserializeDiscounts(attachments),
    optionTypes: deserializeOptionTypes(attachments, product),
    optionValues: deserializeOptionValues(attachments, variant),
    // breadcrumbs: buildBreadcrumbs(attachments, product),
    properties: deserializeProperties(attachments, product),
    displayPrice: variant.attributes.display_price,
    price: variant.attributes.price,
    cost_price: variant.attributes.cost_price,
    compare_at_price: variant.attributes.compare_at_price,
    in_stock: variant.attributes.in_stock,
    total_on_hand: product.attributes.total_on_hand,
    available_on: getDateString(product.attributes.available_on),
    discontinue_on: getDateString(product.attributes.discontinue_on),
    expired: Date.parse(product.attributes.discontinue_on) <= Date.now(),
    des_no_html: product.attributes.des_no_html,
    admin_remark: product.attributes.admin_remark,
    notes: product.attributes.notes,
    status: variant.attributes.status,
    consignment_id: product.attributes.consignment_id,
    order: deserializeLastOrder(attachments, product),
    stock_items: attachments
      .filter(
        (a) => a.type == 'stock_item' && a.attributes.variant_id == variant.id,
      )
      .map((si) => deserializeStockItem(si)),
  }
}

export const deserializeStockItem = (data): StockItem => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    count_on_hand: data.attributes.count_on_hand,
    backorderable: data.attributes.backorderable,
    stock_location_area_id: data.attributes.stock_location_area_id,
    stock_location_id: data.attributes.stock_location_id,
    variant_id: data.attributes.variant_id,
  }
}

export const deserializeDiscounts = (data: any[]): any[] => {
  const ds = data.filter((a) => a.type == 'discount')
  const discounts = ds.map((d) => ({
    id: d.id,
    product_id: d.attributes.product_id,
    discount_percentage: d.attributes.discount_percentage,
    discount_from_day: d.attributes.discount_from_day,
    discount_at: d.attributes.discount_at,
    created_at: d.attributes.created_at,
    updated_at: d.attributes.updated_at,
  }))

  return discounts.sort((a, b) => a.discount_percentage - b.discount_percentage)
}

export const deserializeSingleProductVariants = (
  apiProduct: IProduct,
): ProductVariant[] => {
  const attachments = apiProduct.included
  const productId = apiProduct.data.id
  // primary_variant may not exist if na older version of Spree is used. Only use primary_variant if available.
  const primaryVariantId =
    (apiProduct.data.relationships.primary_variant?.data as RelationType).id ||
    null

  const groupedVariants = groupIncluded<'primaryVariants' | 'optionVariants'>(
    attachments,
    {
      primaryVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        document.id === primaryVariantId,
      optionVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        document.id !== primaryVariantId,
    },
  )

  if (groupedVariants?.optionVariants.length === 0) {
    const images = deserializeImages(
      attachments,
      groupedVariants.primaryVariants,
    )
    return [
      {
        ...partialDeserializeProductVariant(
          apiProduct.data,
          groupedVariants.primaryVariants[0],
          attachments,
        ),
        images,
      },
    ]
  }

  return groupedVariants?.optionVariants.map((variant) => {
    const images = deserializeImages(attachments, [
      ...groupedVariants.primaryVariants,
      variant,
    ])

    return {
      ...partialDeserializeProductVariant(
        apiProduct.data,
        variant,
        attachments,
      ),
      images,
    }
  })
}

export const deserializeProducts = (
  apiProducts: IProducts,
  attachments: JsonApiDocument[],
): ProductVariant[] => {
  return apiProducts.data.map((p) => {
    const variant = attachments.find(
      (a) =>
        a.type === 'variant' &&
        a.id === (p.relationships.default_variant.data as RelationType).id,
    )
    const images = deserializeImages(attachments, [p])

    return deserializeProductVariant(p, variant, images, attachments)
  })
}

export const deserializeProduct = (apiProduct: IProduct): ProductVariant => {
  const images = deserializeImages(apiProduct.included, [apiProduct.data])
  const variant = apiProduct.included.find(
    (a) =>
      a.type === 'variant' &&
      a.id ===
        (apiProduct.data.relationships.default_variant.data as RelationType).id,
  )
  return deserializeProductVariant(
    apiProduct.data,
    variant,
    images,
    apiProduct.included,
  )
}

export const deserializeProductVariant = (
  product,
  variant,
  images,
  attachments: JsonApiDocument[],
): ProductVariant => {
  const taxons = deserializeTaxons(attachments, product)
  return {
    _id: product.id,
    id: product.id,
    _productId: product.id,
    _variantId: variant.id,
    images: images,
    _description:
      variant.attributes.description || product.attributes.description,
    taxons: taxons,
    raw_taxons: product.relationships.taxons.data.map((taxon) => taxon.id),
    brand_id: findTaxonByPermalinkPrefix(taxons, 'brands')?.id,
    category_id: findTaxonByPermalinkPrefix(taxons, 'women')?.id,
    condition_id: findTaxonByPermalinkPrefix(taxons, 'conditions')?.id,
    color_id: findTaxonByPermalinkPrefix(taxons, 'colors')?.id,
    size_id: findTaxonByPermalinkPrefix(taxons, 'sizes')?.id,
    sc_size_id: findTaxonByPermalinkPrefix(taxons, 'sc-sizes')?.id,
    material_id: findTaxonByPermalinkPrefix(taxons, 'materials')?.id,
    discount_id: findTaxonByPermalinkPrefix(taxons, 'discounts')?.id,
    pattern_ids: findTaxonIdsByPermalinkPrefix(taxons, 'patterns'),
    style_ids: findTaxonIdsByPermalinkPrefix(taxons, 'styles'),
    is_genuine: true,
    description:
      variant.attributes.description || product.attributes.description,
    _categoriesRef: product.relationships.taxons.data.map((taxon) => taxon.id),
    name: product.attributes.name,
    slug: product.attributes.slug,
    product_id: product.attributes.product_id,
    sku: product.attributes.sku || variant.attributes.sku,
    addedToCartAt: product.attributes.added_to_cart_at,
    measurements: deserializeMeasurements(attachments),
    product_discounts: deserializeDiscounts(attachments),
    optionTypes: deserializeOptionTypes(attachments, product),
    optionValues: deserializeOptionValues(attachments, variant),
    // breadcrumbs: buildBreadcrumbs(attachments, product),
    properties: deserializeProperties(attachments, product),
    displayPrice: variant.attributes.display_price,
    price: variant.attributes.price,
    cost_price: variant.attributes.cost_price,
    compare_at_price: variant.attributes.compare_at_price,
    in_stock: variant.attributes.in_stock,
    total_on_hand: variant.attributes.total_on_hand,
    available_on: getDateString(product.attributes.available_on),
    discontinue_on: getDateString(product.attributes.discontinue_on),
    expired: Date.parse(product.attributes.discontinue_on) <= Date.now(),
    des_no_html: product.attributes.des_no_html,
    admin_remark: product.attributes.admin_remark,
    notes: product.attributes.notes,
    status: variant.attributes.status,
    consignment_id: product.attributes.consignment_id,
    order: deserializeLastOrder(attachments, product),
    stock_items: attachments
      .filter(
        (a) => a.type == 'stock_item' && a.attributes.variant_id == variant.id,
      )
      .map((si) => deserializeStockItem(si)),
  }
}

export const deserializeLimitedVariants = (
  apiProducts: IProducts,
): ProductVariant[] => {
  const attachments = apiProducts.included

  return apiProducts.data.map((product) => {
    const productId = product.id
    // primary_variant may not exist if na older version of Spree is used. Only use primary_variant if available.
    const primaryVariantId =
      (product.relationships.primary_variant?.data as RelationType).id || null

    const groupedVariants = groupIncluded<
      | 'primaryVariants'
      | 'optionVariants'
      | 'masterVariants'
      | 'nonMasterVariants'
    >(attachments, {
      primaryVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        document.id === primaryVariantId,
      optionVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        document.id !== primaryVariantId,
      masterVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        document.attributes.is_master,
      nonMasterVariants: (document) =>
        isVariantOfProduct(document, productId) &&
        !document.attributes.is_master,
    })

    const images = deserializeImages(attachments, [
      ...groupedVariants.masterVariants,
      ...groupedVariants.nonMasterVariants,
    ])

    let variant

    if (groupedVariants.primaryVariants.length === 0) {
      variant = [
        ...groupedVariants.masterVariants,
        ...groupedVariants.optionVariants,
      ][0]
    } else {
      variant = [
        ...groupedVariants.optionVariants,
        ...groupedVariants.primaryVariants,
      ][0]
    }

    return {
      ...partialDeserializeProductVariant(product, variant, attachments),
      images,
    }
  })
}

const addHostToImage = (image, config: ApiConfig) => ({
  ...image,
  attributes: {
    ...image.attributes,
    original_url: config.backendUrl + image.attributes?.original_url,
    styles: image.attributes?.styles
      ? image.attributes.styles.map((style) => ({
          width: style.width,
          height: style.height,
          url: config.backendUrl + style.url,
        }))
      : [],
  },
})

export const addHostToIncluded = (
  included: JsonApiDocument[],
  config: ApiConfig,
) => included?.map((e) => (e.type === 'image' ? addHostToImage(e, config) : e))

export const addHostToProductImages = <DocumentType extends JsonApiResponse>(
  apiProductsData: DocumentType,
  config: ApiConfig,
): DocumentType => ({
  ...apiProductsData,
  included: addHostToIncluded(apiProductsData.included, config),
})
