import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeUser } from '@/api_client/api/serializers/user'

export default async function getUser(
  { client, config }: ApiContext,
  id: string,
  params: IQuery = {},
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  if (params.filter) {
    params.filter.id_eq = params.filter.id
  }

  params.include = 'ship_address,payout_methods,spree_roles'
  const response = await client.users.show({ bearerToken }, id, params)
  if (response.isSuccess()) {
    const success = response.success()
    // console.log(success)
    const data = success.data
    const included = success.included
    // result.data = result.data.map(deserializeUser)
    return deserializeUser(data, included)
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
