// import { Logger } from '@vue-storefront/core'
import { ApiContext, Order } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeCart } from '../serializers/cart'
import { cartParams } from '@/api_client/api/common/cart'
import { IQuery } from '@/api_client/interfaces/Query'

export default async function returnOrderItem(
  { client, config }: ApiContext,
  line_item_id,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const response = await client.account.returnOrderItem(
      { bearerToken: token },
      line_item_id,
    )

    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      // const data = payload.data.map((order) =>
      //   deserializeCart(order, payload.included, config),
      // )
      // payload.data = deserializedData
      // return { ...payload, data }
      return true
    } else {
      console.log(response.fail())
      // throw response.fail()
      return false
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
