import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { ITaxonResult, ITaxonsResult } from '../interfaces/Taxon'
import { Routes } from '../routes'
import { IToken } from '@/api_client/interfaces/Token'

export default class Taxons extends Http {
  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<ITaxonsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.taxonsPath(),
      token,
      params,
    )) as ITaxonsResult
  }

  public async listCat(
    token: IToken,
    params: IQuery = {},
  ): Promise<ITaxonsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.taxonomiesPath(),
      token,
      params,
    )) as ITaxonsResult
  }

  public async show(
    token: IToken,
    id: string,
    params: IQuery = {},
  ): Promise<ITaxonResult> {
    return (await this.spreeResponse(
      'get',
      Routes.taxonPath(id),
      token,
      params,
    )) as ITaxonResult
  }

  public async create(token: IToken, body): Promise<ITaxonResult> {
    return (await this.spreeResponse(
      'post',
      Routes.taxonsPath(),
      token,
      {},
      body,
    )) as ITaxonResult
  }
}
