// import { OrderAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Order'
import { CouponCode as CouponCodeAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass'
import {
  Cart,
  CouponCode,
  LineItem,
  Payment,
  Promotion,
  Shipment,
} from '../../types'
import { deserializeAddress } from './address'
import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { getDatetimeString } from '@/api_client/api/serializers/getters'
import { OrderAttr } from '@/api_client/interfaces/Order'
import { deserializeUser } from '@/api_client/api/serializers/user'
import {
  deserializeOrderPromotions,
  deserializePromotions,
} from '@/api_client/api/serializers/promotion'
import { deserializeProductVariant } from '@/api_client/api/serializers/product'
import { deserializeStoreCredit } from '@/api_client/api/serializers/store_credit'

const findAttachment = (attachments: any[], id: string, type: string[]) => {
  return attachments.find((e) => e.id === id && type.includes(e.type))
}

const findAttachments = (attachments: any[], id: string, type: string[]) => {
  return attachments.filter((e) => e.id === id && type.includes(e.type))
}

const formatImageUrl = (styles: any[], backendUrl: string): string => {
  // console.log(styles)
  const coverImage = styles.find((s) => s.width == '270' && s.height == '360')
  if (coverImage) {
    return backendUrl.concat(coverImage.url)
  } else return ''
}

const formatOptions = (optionsText: string) => {
  const optionsArray = optionsText?.split(', ') || []
  return optionsArray.reduce((options, e) => {
    const key = e.split(': ')[0].toLowerCase()
    const value = e.split(': ')[1]
    return {
      ...options,
      [key]: value,
    }
  }, {})
}
//
// const scFormatOptions = (optionsText: string) => {
//   const optionsArray = optionsText.split(', ');
//   return optionsArray.reduce((options, e) => {
//     const key = e.split(': ')[0].toLowerCase();
//     const value = e.split(': ')[1];
//     return {
//       ...options,
//       [key]: value
//     };
//   }, {});
// };

export const deserializeLineItem = (
  lineItem: any,
  attachments: any[],
  config: any,
): LineItem => {
  // console.log('deserializeLineItem')
  const variant = findAttachment(
    attachments,
    lineItem.relationships.variant.data.id,
    ['variant', 'sc_variant'],
  )
  // console.log(variant)
  const product = findAttachment(
    attachments,
    variant.relationships.product?.data.id,
    ['product', 'sc_product'],
  )
  // console.log(product)
  let imageUrl = ''
  let inStock = false
  let image
  if (product != null) {
    image =
      product.relationships.images.data &&
      product.relationships.images.data.length > 0
        ? findAttachment(attachments, product.relationships.images.data[0].id, [
            'image',
          ])
        : undefined
    imageUrl = image
      ? formatImageUrl(image.attributes.styles, config.backendUrl)
      : ''
    inStock = product.attributes.in_stock
  } else {
    image =
      variant.relationships.images.data &&
      variant.relationships.images.data.length > 0
        ? findAttachment(attachments, variant.relationships.images.data[0].id, [
            'image',
          ])
        : undefined
    console.log('etttf')
    console.log(variant)
    console.log(image)
    imageUrl = image
      ? formatImageUrl(image.attributes.styles, config.backendUrl)
      : ''
    inStock = variant.attributes.in_stock
  }

  const order = findAttachment(
    attachments,
    lineItem.relationships?.order?.data.id,
    ['order'],
  )

  // console.log('lineItem.relationships.inventory_units')
  // console.log(lineItem.relationships.inventory_units)
  return {
    _id: parseInt(lineItem.id, 10),
    _variantId: parseInt(variant.id, 10),
    _description: '',
    _categoriesRef: [],
    product_id: variant?.attributes?.product_id,
    name: variant.attributes.name,
    inStock: inStock,
    sku: variant.attributes.sku,
    in_stock_amount: variant.attributes.total_on_hand,
    item_status: variant.attributes.status,
    image: imageUrl,
    slug: '/p/' + variant.id + '/' + lineItem.attributes.slug,
    price: {
      original: lineItem.attributes.compare_at_price,
      current: lineItem.attributes.price,
    },
    displayPrice: lineItem.attributes.display_price,
    displayTotal: lineItem.attributes.display_total,
    isChanged: false,
    qty: lineItem.attributes.quantity,
    options: formatOptions(lineItem.attributes.options_text),
    order: deserializeCart(order, [], config),
    // variant: deserializeProductVariant(product, variant, [image], attachments),
    inventory_units: lineItem.relationships?.inventory_units?.data?.map((u) => {
      const unit = findAttachment(attachments, u.id, ['inventory_unit'])
      // console.log('u.id:' + u.id)
      // console.log(attachments)
      if (unit === undefined) {
        return null
      }
      // console.log('unit:' + unit)
      return {
        id: unit.id,
        order_id: unit.relationships?.order.data?.id,
        variant_id: unit.relationships?.variant.data?.id,
        shipment_id: unit.relationships?.shipment.data?.id,
        state: unit.attributes.state,
        pending: unit.attributes.pending,
        quantity: unit.attributes.quantity,
      }
    }),
  }
}

// const deserializePromotion = (promotion: any): Promotion => {
//   return {
//     name: promotion.attributes.name,
//     amount: promotion.attributes.amount,
//     code: promotion.attributes.code,
//   }
// }

const deserializePayment = (payment: any, include: any[] = []): Payment => {
  const payment_method_id =
    payment.attributes?.payment_method_id?.toString() ||
    payment.relationships?.payment_method?.data?.id
  const paymentMethod = include.find(
    (i) => i.id.toString() == payment_method_id && i.type == 'payment_method',
  )

  return {
    id: payment.id,
    paymentMethodId: payment_method_id,
    paymentMethodName: paymentMethod?.attributes.name,
    paymentMethodDescription: paymentMethod?.attributes.description,
    paymentMethodType: paymentMethod?.attributes.type,
    amount: payment.attributes.amount,
    number: payment.attributes.number,
    state: payment.attributes.state,
  }
}

const deserializeShipment = (shipment: any): Shipment => {
  return {
    id: shipment.id,
    additional_tax_total: shipment.attributes.additional_tax_total,
    adjustment_total: shipment.attributes.adjustment_total,
    cost: shipment.attributes.cost,
    included_tax_total: shipment.attributes.included_tax_total,
    non_taxable_adjustment_total:
      shipment.attributes.non_taxable_adjustment_total,
    pre_tax_amount: shipment.attributes.pre_tax_amount,
    promo_total: shipment.attributes.promo_total,
    private_metadata: shipment.attributes.private_metadata,
    public_metadata: shipment.attributes.public_metadata,
    number: shipment.attributes.number,
    state: shipment.attributes.state,
    tracking: shipment.attributes.tracking,
    tracking_url: shipment.attributes.tracking_url,
    created_at: shipment.attributes.created_at,
    shipped_at: shipment.attributes.shipped_at,
    updated_at: shipment.attributes.updated_at,
  }
}

const filterIncludedLineItems = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  if (lineItemData == null) {
    return null
  }
  return included.filter(
    (e) =>
      e.type === 'line_item' &&
      (lineItemData as RelationType[]).find((d) => d.id === e.id) !== undefined,
  )
}

const filterIncludedPromotions = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  return included.filter((e) => e.type === 'order_promotion')
}

const filterIncludedStoreCredits = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  return included.filter((e) => e.type === 'store_credit')
}

const filterIncludedPayments = (
  lineItemData: RelationType[],
  included: any[],
) => {
  return included.filter(
    (e) =>
      e.type === 'payment' &&
      (lineItemData as RelationType[]).find((d) => d.id === e.id) !== undefined,
  )
}

const filterIncludedShipments = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  return included.filter((e) => e.type === 'shipment')
}

const findPaymentMethod = (data, included) => {
  const paymentMethod = included.find((e) => e.type === 'payment')
  if (paymentMethod === null || paymentMethod === undefined) {
    return null
  }
  return paymentMethod.attributes.payment_method_id
}

const findUserId = (data) => {
  return data.relationships?.user?.data?.id
}

const findAddress = (data, included) => {
  const shippingAddressId =
    data.relationships?.ship_address?.data?.id || undefined
  const billingAddressId =
    data.relationships?.bill_address?.data?.id || undefined

  const shippingAddress = findAttachment(included, shippingAddressId, [
    'address',
    'sc_address',
  ])
  const billingAddress = findAttachment(included, billingAddressId, [
    'address',
    'sc_address',
  ])

  return {
    shipping: shippingAddress
      ? deserializeAddress(
          shippingAddress,
          data.attributes.name,
          data.attributes.phone,
        )
      : undefined,
    billing: billingAddress
      ? deserializeAddress(
          billingAddress,
          data.attributes.name,
          data.attributes.phone,
        )
      : undefined,
  }
}

export const deserializeCart = (
  apiCart: OrderAttr,
  included: any[] = [],
  config: any,
): Cart => {
  if (apiCart == null) {
    // console.log('apiCart == null')
    return null
  }
  // console.log(apiCart)
  // console.log(+apiCart.attributes.commission_total)
  return {
    _id: parseInt(apiCart.id, 10),
    id: +apiCart.id,
    user_id: findUserId(apiCart),
    approver_id: (apiCart?.relationships?.approver?.data as RelationType)?.id,
    email: apiCart.attributes.email,
    number: apiCart.attributes.number,
    state: apiCart.attributes.state,
    payment_state: apiCart.attributes.payment_state,
    shipment_state: apiCart.attributes.shipment_state,
    channel: apiCart.attributes.channel,
    phone: apiCart.attributes.phone,
    total: apiCart.attributes.display_total,
    selectedPaymentMethodId: findPaymentMethod(apiCart, included),
    totalAmount: parseFloat(apiCart.attributes.total),
    itemTotalAmount: parseFloat(apiCart.attributes.item_total),
    itemTotal: apiCart.attributes.display_item_total,
    shipTotal: apiCart.attributes.display_ship_total,
    promo_total: +apiCart.attributes.promo_total,
    commission_total: +apiCart.attributes.commission_total,
    shipTotalAmount: parseFloat(apiCart.attributes.shipment_total),
    taxTotalAmount: parseFloat(apiCart.attributes.tax_total),
    adjustmentTotal: parseFloat(apiCart.attributes.adjustment_total),
    lineItems: filterIncludedLineItems(
      apiCart.relationships?.line_items?.data,
      included,
    )?.map((item) => deserializeLineItem(item, included, config)),
    promotions: filterIncludedPromotions(
      apiCart.relationships?.promotions?.data || [],
      included,
    ).map((item) => deserializeOrderPromotions(item, [])),
    payments: filterIncludedPayments(
      (apiCart.relationships?.payments?.data as RelationType[]) || [],
      included,
    ).map((item) => deserializePayment(item, included)),
    shipments: filterIncludedShipments(
      apiCart.relationships?.shipments?.data || [],
      included,
    ).map((item) => deserializeShipment(item)),
    user: deserializeUser(
      findAttachment(included, findUserId(apiCart), ['user', 'sc_user']),
      [],
    ),
    created_by: deserializeUser(
      findAttachment(
        included,
        (apiCart.relationships?.created_by?.data as RelationType)?.id,
        ['user', 'sc_user'],
      ),
      [],
    ),
    approver: deserializeUser(
      findAttachment(
        included,

        (apiCart.relationships?.approver?.data as RelationType)?.id,
        ['user', 'sc_user'],
      ),
      [],
    ),
    canceler: deserializeUser(
      findAttachment(
        included,
        (apiCart.relationships?.canceler?.data as RelationType)?.id,
        ['user', 'sc_user'],
      ),
      [],
    ),
    itemCount: apiCart.attributes.item_count,
    address: findAddress(apiCart, included),
    completed_at_o: apiCart.attributes.completed_at,
    completed_at:
      apiCart.attributes.completed_at == null
        ? null
        : getDatetimeString(new Date(apiCart.attributes.completed_at)),
    created_at_o: apiCart.attributes.created_at,
    created_at: getDatetimeString(new Date(apiCart.attributes.created_at)),
    token: apiCart.attributes.token,
    display_total_applicable_store_credit:
      apiCart.attributes.display_total_applicable_store_credit,
    display_order_total_after_store_credit:
      apiCart.attributes.display_order_total_after_store_credit,
    display_subtotal: apiCart.attributes.display_subtotal,
    display_promo_total: apiCart.attributes.display_promo_total,
    display_adjustment_total: apiCart.attributes.display_adjustment_total,
    display_shipment_total: apiCart.attributes.display_shipment_total,
    temp_payments: apiCart.attributes.private_metadata?.payments || [],
    temp_payment_method_id:
      apiCart.attributes.private_metadata.payment_method_id,
    temp_shipping_method_id:
      apiCart.attributes.private_metadata.shipping_method_id,
    order_total_after_store_credit:
      +apiCart.attributes.order_total_after_store_credit,
    note: apiCart.attributes.public_metadata.note,
    total_applicable_store_credit:
      +apiCart.attributes.total_applicable_store_credit,
    estimated_shipping_fee:
      apiCart.attributes.private_metadata.estimated_shipping_fee,
    is_free_ship:
      apiCart.attributes.private_metadata.is_free_ship?.toString() === 'true' ||
      false,
    total_available_store_credit:
      +apiCart.attributes.total_available_store_credit,
    store_credit_remaining_after_capture:
      +apiCart.attributes.total_available_store_credit -
      +apiCart.attributes.total_applicable_store_credit,
    customer_pay: apiCart.attributes.private_metadata.customer_pay || '',
    shipper_code: apiCart.attributes.private_metadata.shipper_code || '',
    deposit: apiCart.attributes.private_metadata.deposit || 0,
    is_apply_credit:
      apiCart.attributes.private_metadata.is_apply_credit?.toString() ===
        'true' || false,
    deposit_payment_method_id:
      apiCart.attributes.private_metadata.deposit_payment_method_id || null,
    store_credits: filterIncludedStoreCredits(
      apiCart.relationships?.store_credits?.data,
      included,
    ).map((item) => deserializeStoreCredit(item, included)),
  }
}

export const serializeCouponCode = ({
  couponCode,
}: CouponCode): CouponCodeAttr => ({
  coupon_code: couponCode,
})
