import { ApiContext, Consignment } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import {
  deserializeConsignment,
  deserializeConsignmentLineItem,
} from '../serializers/consignment'
import { consignmentParams } from '@/api_client/api/common/consignment'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToIncluded,
  addHostToProductImages,
} from '@/api_client/api/serializers/product'

export default async function getConsignmentLineItems(
  { client, config }: ApiContext,
  consignmentId,
  consignmentNumber,
  useNumber = false,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })
    const filter = { consignment_id_eq: '', consignment_number_eq: '' }
    if (useNumber) {
      filter.consignment_number_eq = consignmentNumber
    } else {
      filter.consignment_id_eq = consignmentId
    }
    const response = await client.consignments.lineItems(
      { bearerToken: token },
      {
        filter,
        include: 'variant,variant.images,discounts',
        sort: 'id',
        per_page: 500,
      },
    )

    if (response.isSuccess()) {
      const payload = response.success()
      // console.log('params[:filter][:consignment_number]')
      console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)

      return payload.data.map((d, index) =>
        deserializeConsignmentLineItem(
          d,
          payload.included,
          config,
          index + 1,
          consignmentNumber,
        ),
      )
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
