import {
  Account,
  Products,
  Taxons,
  Countries,
  Cart,
  Checkout,
  Authentication,
  Order,
  Users,
} from './endpoints'
import Consignments from '@/api_client/endpoints/Consignments'
import Payouts from '@/api_client/endpoints/Payouts'
import Images from '@/api_client/endpoints/Images'
import Report from '@/api_client/endpoints/Report'
import Delivery from '@/api_client/endpoints/Delivery'
import Shipments from '@/api_client/endpoints/Shipments'
import Tools from '@/api_client/endpoints/Tools'
import Payment from '@/api_client/endpoints/Payment'
import LogEntry from '@/api_client/endpoints/LogEntry'
import StockLocation from '@/api_client/endpoints/StockLocation'
import StockItem from '@/api_client/endpoints/StockItem'
import StoreCredit from '@/api_client/endpoints/StoreCredit'
import CmsPages from '@/api_client/endpoints/CmsPages'

export interface IClientConfig {
  host?: string
}

class Client {
  public products: Products
  public tools: Tools
  public taxons: Taxons
  public countries: Countries
  public cart: Cart
  public checkout: Checkout
  public authentication: Authentication
  public account: Account
  public order: Order
  public users: Users
  public consignments: Consignments
  public payouts: Payouts
  public cms_pages: CmsPages
  public shipments: Shipments
  public report: Report
  public payment: Payment
  public delivery: Delivery
  public logEntry: LogEntry
  public stockLocation: StockLocation
  public stockItem: StockItem
  public images: Images
  public storeCredit: StoreCredit
  protected host?: string

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  protected addEndpoints() {
    this.account = this.makeAccount()
    this.authentication = this.makeAuthentication()
    this.cart = this.makeCart()
    this.checkout = this.makeCheckout()
    this.countries = this.makeCountries()
    this.order = this.makeOrder()
    this.products = this.makeProducts()
    this.tools = this.makeTools()
    this.taxons = this.makeTaxons()
    this.users = this.makeUsers()
    this.consignments = this.makeConsignments()
    this.payouts = this.makePayouts()
    this.cms_pages = this.makeCMSPages()
    this.shipments = this.makeShipments()
    this.report = this.makeReports()
    this.payment = this.makePayment()
    this.images = this.makeImages()
    this.delivery = this.makeDelivery()
    this.logEntry = this.makeLogEntry()
    this.stockLocation = this.makeStockLocation()
    this.stockItem = this.makeStockItem()
    this.storeCredit = this.makeStoreCredit()
  }

  protected makeAccount() {
    return new Account(this.host)
  }

  protected makeAuthentication() {
    return new Authentication(this.host)
  }

  protected makeCart() {
    return new Cart(this.host)
  }

  protected makeCheckout() {
    return new Checkout(this.host)
  }

  protected makeCountries() {
    return new Countries(this.host)
  }

  protected makeOrder() {
    return new Order(this.host)
  }

  protected makeProducts() {
    return new Products(this.host)
  }

  protected makeTaxons() {
    return new Taxons(this.host)
  }

  protected makeUsers() {
    return new Users(this.host)
  }

  protected makeConsignments() {
    return new Consignments(this.host)
  }

  protected makePayouts() {
    return new Payouts(this.host)
  }
  protected makeCMSPages() {
    return new CmsPages(this.host)
  }

  protected makeShipments() {
    return new Shipments(this.host)
  }

  protected makeReports() {
    return new Report(this.host)
  }

  protected makePayment() {
    return new Payment(this.host)
  }

  protected makeTools() {
    return new Tools(this.host)
  }

  protected makeImages() {
    return new Images(this.host)
  }

  protected makeDelivery() {
    return new Delivery(this.host)
  }

  protected makeLogEntry() {
    return new LogEntry(this.host)
  }

  protected makeStockLocation() {
    return new StockLocation(this.host)
  }

  protected makeStockItem() {
    return new StockItem(this.host)
  }

  protected makeStoreCredit() {
    return new StoreCredit(this.host)
  }
}

export default Client
