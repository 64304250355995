// import { Shipment, ShippingRate } from '../..';
import { extractRelationships } from './common'
import { Shipment, ShippingRate } from '@/api_client/types'
import { Shipping } from '@/api_client/types/shipping'

export const deserializeShippingRate = (method): ShippingRate => ({
  id: method.id,
  methodId: method.attributes.shipping_method_id,
  name: method.attributes.name,
  selected: method.attributes.selected,
  cost: method.attributes.cost,
})

export const deserializeShipment = (shipment, included): Shipping => {
  // const shippingRates = extractRelationships(
  //   included,
  //   'shipping_rate',
  //   'shipping_rates',
  //   shipment,
  // )

  return {
    id: shipment.id,
    name: shipment.attributes?.name || shipment.name,
    code: shipment.attributes?.code || shipment.code,
    admin_name: shipment.attributes?.admin_name || shipment.admin_name,
    display_on: shipment.attributes?.display_on || shipment.display_on,
    tracking_url: shipment.attributes?.tracking_url || shipment.tracking_url,
  }
}
