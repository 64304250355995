
import AppBreadcrumb from './AppBreadcrumb.vue'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import { logo } from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  created() {
    // console.log('this.$route.name')
    // console.log(this.$route.path.includes('tools'))
    this.lang = localStorage.getItem('lang') || 'vi'
  },
  methods: {
    test() {
      this.$root.$i18n.locale = 'en'
    },
    langChange(lang) {
      this.$root.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.lang = lang
    },
  },
  watch: {
    // call again the method if the route changes
    '$route.path': {
      handler: function () {
        this.isTool = this.$route.path.includes('tools')
      },
    },
  },
  data() {
    return { lang: null, isTool: this.$route.path.includes('tools') }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {
      logo,
    }
  },
}
