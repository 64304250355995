import { ApiContext } from '../../types'
import getCurrentCartToken from '../authentication/getCurrentCartToken'
import { deserializeShipment } from '../serializers/shipping'
import getCurrentBearerOrCartToken from '@/api_client/api/authentication/getCurrentBearerOrCartToken'

export default async function getShipments({ client, config }: ApiContext) {
  try {
    // const token = await getCurrentCartToken(config)
    // console.log('getShipments');
    // console.log(token);
    const token = await getCurrentBearerOrCartToken({ client, config })
    const result = await client.checkout.shippingMethods(token)
    if (result.isSuccess()) {
      // console.log('success')

      const payload = result.success()
      // console.log(payload)
      const shipments = payload.data.map((e) =>
        deserializeShipment(e, payload.included),
      )
      // shipments[0].availableShippingRates.sort(
      //   (i, y) => parseInt(y.cost) - parseInt(i.cost),
      // )
      return shipments
    } else {
      // console.log('not success');
      console.log(result.fail())
      return null
    }
  } catch (e) {
    console.error('getShipments:', e)
    // throw e;
    return null
  }
}
