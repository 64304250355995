export const getDatetimeString = (date: any): string => {
  // TODO: update all
  if (date == null) {
    return null
  }
  const tsDateTime = new Date(date)
  return (
    getDateString(tsDateTime.toString()) +
    ' ' +
    getTimeString(tsDateTime.toString())
  )
}

export const getDateString = (datetime: string): string => {
  // new Date(date).toLocaleDateString('en-US')
  if (datetime == null || datetime === '') {
    return null
  }
  let tmpDatetime = null
  if (typeof datetime == 'string') {
    tmpDatetime = new Date(datetime)
  } else {
    tmpDatetime = datetime
  }
  return (
    tmpDatetime.getFullYear() +
    '-' +
    (tmpDatetime.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    tmpDatetime.getDate().toString().padStart(2, '0')
  )
}
export const getTimeString = (datetime: string): string => {
  if (datetime == null || datetime === '') {
    return null
  }
  let tmpDatetime = null
  if (typeof datetime == 'string') {
    tmpDatetime = new Date(datetime)
  } else {
    tmpDatetime = datetime
  }
  return tmpDatetime.toLocaleTimeString()
}
