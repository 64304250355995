import { ApiContext, Cart } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function updateOrder(
  { client, config }: ApiContext,
  orderId,
  params,
  isShowErrorAlert = true,
): Promise<Cart> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.update(
      { bearerToken: token },
      orderId,
      {
        ...cartParams,
      },
      { ...params },
      isShowErrorAlert,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      const cart = deserializeCart(payload.data, payload.included, config)
      return cart
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
