import Http from '../Http'
import {
  AddItem,
  CouponCode,
  EstimateShippingMethods,
  SetQuantity,
} from '../interfaces/endpoints/CartClass'
import { IEstimatedShippingMethodsResult } from '../interfaces/EstimatedShippingMethod'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import removeLineItem from '@/api_client/api/removeLineItem'

export default class Cart extends Http {
  public async show(
    token: IToken,
    orderId: number,
    params: IQuery = {},
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'get',
      Routes.orderPath(orderId),
      token,
      params,
    )) as IOrderResult
  }

  public async delete(token: IToken, orderId: number): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.orderPath(orderId),
      token,
    )) as IOrderResult
  }

  public async create(
    token?: IToken,
    params: IQuery = {},
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'post',
      Routes.ordersPath(),
      token,
      params,
    )) as IOrderResult
  }

  public async update(
    token: IToken,
    orderId: number,
    params: any,
    data: any,
    isShowErrorAlert = true,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.orderPath(orderId),
      token,
      params,
      data,
      isShowErrorAlert,
    )) as IOrderResult
  }

  public async complete(
    token: IToken,
    orderId: number,
    params: any,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.completeOrderPath(orderId),
      token,
      params,
    )) as IOrderResult
  }

  public async setShippingMethod(
    token: IToken,
    orderId: number,
    params: any,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.orderShippingMethodPath(orderId),
      token,
      params,
    )) as IOrderResult
  }

  public async addItem(
    token: IToken,
    orderId,
    params: any,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'post',
      Routes.orderAddItemPath(orderId),
      token,
      params,
    )) as IOrderResult
  }

  public async addItem1(
    token: IToken,
    orderId,
    params: any,
    data,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'post',
      Routes.orderAddItemPath1(orderId),
      token,
      params,
      data,
    )) as IOrderResult
  }

  public async removeLineItem(
    token: IToken,
    orderId,
    lineItemId: number,
    params: any,
    data,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.orderRemoveItemPath1(orderId, lineItemId),
      token,
      params,
      data,
    )) as IOrderResult
  }

  public async removeOrder(token: IToken, orderId): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.returnOrderPath(orderId),
      token,
    )) as IOrderResult
  }

  public async removeItem(
    token: IToken,
    id: string,
    lineItemId: number,
    params: any,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.orderRemoveItemPath(id, lineItemId),
      token,
      params,
    )) as IOrderResult
  }

  public async emptyCart(
    token: IToken,
    params: IQuery = {},
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.cartEmptyPath(),
      token,
      params,
    )) as IOrderResult
  }

  public async setQuantity(
    token: IToken,
    params: SetQuantity,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.cartSetItemQuantity(),
      token,
      params,
    )) as IOrderResult
  }

  public async applyCouponCode(
    token: IToken,
    id: string,
    params: CouponCode,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.cartApplyCodePath(id),
      token,
      params,
    )) as IOrderResult
  }

  public async removeCouponCode(
    token: IToken,
    orderId,
    promotion_id: string,
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.removeCouponPath(orderId, promotion_id),
      token,
      {},
    )) as IOrderResult
  }

  public async estimateShippingMethods(
    token: IToken,
    params: EstimateShippingMethods,
  ): Promise<IEstimatedShippingMethodsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.cartEstimateShippingMethodsPath(),
      token,
      params,
    )) as IEstimatedShippingMethodsResult
  }

  public async metaData(token: IToken): Promise<any> {
    return await this.spreeResponse('get', Routes.ordersMetaPath(), token)
  }
}
