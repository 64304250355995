import { ApiContext, Consignment } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { deserializeConsignment } from '../serializers/consignment'
import { consignmentParams } from '@/api_client/api/common/consignment'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToIncluded,
  addHostToProductImages,
} from '@/api_client/api/serializers/product'

export default async function discountAllConsignmentItems(
  { client, config }: ApiContext,
  consignmentId,
  discount: number,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.consignments.discountAllItems(
      { bearerToken: token },
      consignmentId,
      { discount },
    )

    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      return true
      // payload.included = addHostToIncluded(payload.included, config)

      // return deserializeConsignment(payload.data, payload.included, config)
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
