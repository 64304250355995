import { getDatetimeString } from '@/api_client/api/serializers/getters'

export const deserializeLogEntry = (logEntry): any => {
  if (logEntry == null) {
    return null
  }

  return {
    id: logEntry.id,
    source_type: logEntry.attributes.source_type,
    details: logEntry.attributes.details,
    created_at: getDatetimeString(logEntry.attributes.created_at),
  }
}
