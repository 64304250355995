import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { platformPath, Routes } from '@/api_client/routes'
import { IAccountResult } from '@/api_client/interfaces/Account'
import Axios, { AxiosRequestConfig } from 'axios'
import * as qs from 'qs'

export default async function getFreeShipAmount({
  client,
  config,
}: ApiContext) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.delivery.free_ship({ bearerToken })
  if (response.isSuccess()) {
    return response.success().free_ship
  } else {
    return null
  }
}
