import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeLogEntry } from '@/api_client/api/serializers/log_entry'

export default async function getLogEntries(
  { client, config }: ApiContext,
  source_type: string,
  source_id: string,
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.logEntry.show(
    { bearerToken },
    {
      filter: { source_id_eq: source_id, source_type_eq: source_type },
      sort: '-updated_at',
    },
  )
  if (response.isSuccess()) {
    const data = response.success().data
    return data.map((d) => deserializeLogEntry(d))
  } else {
    return []
  }
}
