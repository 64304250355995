import { ApiContext, Consignment } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { deserializeConsignment } from '../serializers/consignment'
import { consignmentParams } from '@/api_client/api/common/consignment'
import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToIncluded,
  addHostToProductImages,
} from '@/api_client/api/serializers/product'
import { deserializePayout } from '@/api_client/api/serializers/payout'

export default async function getPayout(
  { client, config }: ApiContext,
  payoutNumber,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const params = {
      use_number: 'true',
      include:
        'user,user.payout_methods,approver,created_by,payout_items,payout_items.consignment_line_item,payout_items.consignment_line_item.variant,payout_method,image',
      use_product_id: '',
    }

    const response = await client.payouts.show(
      { bearerToken: token },
      payoutNumber,
      params,
    )

    // console.log(response)
    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)
      // console.log(payload.included)
      return deserializePayout(payload.data, payload.included, config)
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
