import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CSidebarBrand = _resolveComponent("CSidebarBrand")!
  const _component_AppSidebarNav = _resolveComponent("AppSidebarNav")!
  const _component_CSidebarToggler = _resolveComponent("CSidebarToggler")!
  const _component_CSidebar = _resolveComponent("CSidebar")!

  return (_openBlock(), _createBlock(_component_CSidebar, {
    position: "fixed",
    selfHiding: "md",
    unfoldable: $setup.sidebarUnfoldable,
    visible: $setup.sidebarVisible,
    onVisibleChange: _cache[1] || (_cache[1] = 
      (event) =>
        _ctx.$store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CSidebarBrand, null, {
        default: _withCtx(() => [
          _createVNode(_component_CIcon, {
            customClassName: "sidebar-brand-full",
            icon: $setup.logoNegative,
            height: 35
          }, null, 8, ["icon"]),
          _createVNode(_component_CIcon, {
            customClassName: "sidebar-brand-narrow",
            icon: $setup.sygnet,
            height: 35
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createVNode(_component_AppSidebarNav),
      _createVNode(_component_CSidebarToggler, {
        class: "d-none d-lg-flex",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleUnfoldable')))
      })
    ]),
    _: 1
  }, 8, ["unfoldable", "visible"]))
}