import { ApiContext } from '../../types'
import getCurrentCartToken from '../authentication/getCurrentCartToken'
import { deserializeShipment } from '../serializers/shipping'
import getCurrentBearerOrCartToken from '@/api_client/api/authentication/getCurrentBearerOrCartToken'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function placeShipmentDelivery(
  { client, config }: ApiContext,
  id,
) {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.shipments.place({ bearerToken: token }, id)
    if (response.isSuccess()) {
      // console.log(response.success())
      return true
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
