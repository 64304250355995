import { Address } from '../../types'
import { JsonApiDocument } from '@spree/storefront-api-v2-sdk/src/interfaces/JsonApi'

export interface ScAddress {
  firstname: string
  lastname: string
  address1: string
  address2?: string
  city: string
  zipcode: string
  ward: string
  district: string
  state_name: string
  country_iso: string
  phone?: string
  company?: string
}

export interface ScAccountAddressAttr extends JsonApiDocument {
  attributes: ScAddress
}

export const deserializeAddress = (
  apiAddress: ScAccountAddressAttr,
  name = null,
  phone = null,
): Address => {
  if (apiAddress == null) {
    return null
  }
  return {
    _id: apiAddress.id,
    id: apiAddress.id,
    firstname: apiAddress.attributes.firstname || name,
    lastname: apiAddress.attributes.lastname || name,
    company: apiAddress.attributes.company,
    address1: apiAddress.attributes.address1,
    address2: apiAddress.attributes.address2,
    zipcode: apiAddress.attributes.zipcode,
    ward: apiAddress.attributes.ward,
    district: apiAddress.attributes.district,
    city: apiAddress.attributes.city,
    state_name: apiAddress.attributes.state_name,
    country_iso: apiAddress.attributes.country_iso,
    phone: apiAddress.attributes.phone || phone,
  }
}

export const serializeAddress = (address: Address): ScAddress => ({
  firstname: address.firstname,
  lastname: address.firstname,
  company: address.company,
  address1: address.address1,
  address2: address.address2,
  zipcode: address.zipcode,
  city: address.state_name,
  ward: address.ward,
  district: address.district,
  state_name: address.state_name,
  country_iso: address.country_iso,
  phone: address.phone,
})
