import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function updateShipmentTrackNumber(
  { client, config }: ApiContext,
  id,
  number,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.shipments.updateShipmentTrackNumber(
      { bearerToken: token },
      id,
      number,
    )
    if (response.isSuccess()) {
      // console.log(response.success())
      return true
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
