import { ConsignmentLineItem } from '@/api_client/types'
import { User } from '@/api_client/types/user'

export const format = (store_credit): number => (store_credit || 0) / 1000

const storeCreditGetters = {
  format,
}

export default storeCreditGetters
