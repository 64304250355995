import Http from '../Http'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class StockItem extends Http {
  public async update(
    token: IToken,
    id,
    data,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'patch',
      Routes.stockItemPath(id),
      token,
      params,
      data,
    )) as any
  }

  public async updateStockOnly(
    token: IToken,
    data,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.stockItemsPath(),
      token,
      params,
      data,
    )) as any
  }
}
