import { ApiContext, Image } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function addImage(
  { client, config }: ApiContext,
  variantId,
  image,
  viewable_type = 'Spree::Variant',
): Promise<Image> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const data = new FormData()
    data.append('image[attachment]', image)
    data.append('image[viewable_id]', variantId.toString())
    data.append('image[viewable_type]', viewable_type)
    const result = await client.images.create({ bearerToken: token }, data)

    if (result.isSuccess()) {
      const data = result.success().data
      return {
        id: parseInt(data.id, 10),
        original_url: data.attributes.original_url,
        styles: data.attributes.styles.map((style) => ({
          url: config.backendUrl + style.url,
          width: style.width,
          height: style.height,
        })),
        position: data.attributes.position,
      }
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
