import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { platformPath, Routes } from '@/api_client/routes'
import { IAccountResult } from '@/api_client/interfaces/Account'
import Axios, { AxiosRequestConfig } from 'axios'
import * as qs from 'qs'

export default async function getShipmentFee(
  { client, config }: ApiContext,
  address,
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.delivery.fee({ bearerToken }, { address })
  if (response.isSuccess()) {
    return response.success()
  } else {
    return null
  }
}
