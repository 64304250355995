import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeStoreCredit } from '@/api_client/api/serializers/store_credit'

export default async function getStoreCredits(
  { client, config }: ApiContext,
  user_id,
  params,
) {
  const bearerToken = await getCurrentBearerToken({ client, config })
  const result = await client.storeCredit.show(
    { bearerToken },
    {
      filter: { user_id_eq: user_id },
      include: 'created_by,store_credit_category',
      page: params.page || 1,
      per_page: params.per_page || 20,
      sort: '-id',
    },
  )
  if (result.isSuccess()) {
    const payload = result.success()
    const data = payload.data
    const included = payload.included
    return {
      data: data.map((d) => deserializeStoreCredit(d, included)),
      meta: payload.meta,
    }
  } else {
    throw result.fail()
  }
}
