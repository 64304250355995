import Http from '../Http'
import { IOrderResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class StoreCredit extends Http {
  public async show(token: IToken, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.storeCreditsPath(),
      token,
      params,
    )) as any
  }

  public async create(token: IToken, data: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.storeCreditsPath(),
      token,
      {},
      data,
    )) as any
  }

  public async delete(token: IToken, id): Promise<any> {
    return (await this.spreeResponse(
      'delete',
      Routes.storeCreditPath(id),
      token,
      {},
    )) as any
  }
}
